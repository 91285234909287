import { useToast } from '@chakra-ui/react';
import { Order } from 'smartdishpro-scripts/ts_common';
import { useAsync } from '../useAsync';
import { CartActionType, useCart } from '.';
import { useCustomer } from '../useCustomer';
import { createOrders, getOrders } from '../../repositories/order';
import { checkStoreOpen } from '../useInit/checkStoreOpen';
import { t } from 'i18next';
import { getItem } from '../../repositories/items';
import {
  convertOrderItems,
  finishedCourseItemIds,
} from '../../utils/converter';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useOrder = (): {
  order: () => void;
  isLoading: boolean;
  isDone: boolean;
} => {
  const toast = useToast();
  const [isDone, setIsDone] = useState<boolean>(false);
  const { items, orders, trans, dispatch: cartDispatch } = useCart();
  const { id, merchantId, store } = useCustomer();
  const { isLoading, execute: order } = useAsync(async () => {
    if (!id || !merchantId || !store?.id || !trans?.id) {
      toast({
        title: t('error1'),
        status: 'error',
        position: 'bottom',
        isClosable: true,
      });
      return;
    }

    const result = await checkStoreOpen(merchantId, store?.id);
    if (!result.store?.isOpen || !result.storeHourId) {
      toast({
        title: t('finishHours'),
        status: 'error',
        position: 'bottom',
        isClosable: true,
      });
      return;
    }

    if (items.length === 0) {
      toast({
        title: t('cannnotSendOrder'),
        status: 'error',
        position: 'bottom',
        isClosable: true,
      });
      return;
    }

    // ラストオーダーの時間が過ぎているコース商品
    let _finishedCourseItemIds: string[] = [];
    if (trans.courseFinishAt !== null) {
      const _orderHistories = await getOrders(
        merchantId,
        store.id,
        trans?.id ?? ''
      );
      _finishedCourseItemIds = finishedCourseItemIds(_orderHistories);
    }

    const _flatItems = convertOrderItems(items);
    for (let index = 0; index < _flatItems.length; index++) {
      const _item = await getItem(
        merchantId,
        store.id,
        _flatItems[index].itemId
      );
      if (_item == null) {
        toast({
          title: t('error1'),
          status: 'error',
          position: 'bottom',
          isClosable: true,
        });
        return;
      } else if (_item!.soldOut) {
        toast({
          title: `${_item!.itemName.ja}は売り切れです`,
          status: 'error',
          position: 'bottom',
          isClosable: true,
        });
        return;
      } else if (
        _item!.numOfCurrentStock !== null &&
        _item!.numOfCurrentStock! < _flatItems[index].quantity
      ) {
        toast({
          title: `${_item!.itemName.ja}は残り${_item!
            .numOfCurrentStock!}個です`,
          status: 'error',
          position: 'bottom',
          isClosable: true,
        });
        return;
      } else if (!_item!.storeHourIds.includes(result.storeHourId)) {
        toast({
          title: `ただ今の時間注文できないメニューが含まれています。QRコードを読み直してください。`,
          status: 'error',
          position: 'bottom',
          isClosable: true,
        });
        return;
      } else if (
        _item.courseItemIds.some((_id) => _finishedCourseItemIds.includes(_id))
      ) {
        toast({
          title: `ラストオーダー時間が過ぎたコースメニューが含まれています。`,
          status: 'error',
          position: 'bottom',
          isClosable: true,
        });
        return;
      } else {
        continue;
      }
    }

    const _orders: Order[] = items.map((_item, index) => {
      const _id = uuidv4();
      return {
        id: _id,
        createdAt: new Date(),
        createdStaff: null,
        customerId: id,
        deletedAt: null,
        deviceCode: null,
        documentStatus: 'active',
        firstOrder: orders.length === 0,
        orderItem: { ..._item, orderId: _id },
        sortNum: index,
        updatedAt: new Date(),
      };
    });

    await createOrders(merchantId, store.id, trans.id, _orders);

    const newOrders = await getOrders(merchantId, store.id, trans.id);
    if (newOrders.length < 1) {
      throw new Error();
    }

    cartDispatch({
      type: CartActionType.CLEAR,
      payloadTrans: trans,
      payloadOrders: newOrders,
    });

    toast({
      title: t('sendOrder'),
      status: 'error',
      position: 'bottom',
      isClosable: true,
    });
    setIsDone(true);
  });

  return {
    isLoading,
    isDone,
    order,
  };
};
