import * as sdpro from 'smartdishpro-scripts/ts_common';
import { createContext, useContext, useReducer, VFC } from 'react';
import { CustomerAction, customerReducer } from './customerReducer';
import { ReactNode } from 'react-router/node_modules/@types/react';
import { TransHistory } from '../useHistory/useHistory';

export type Customer = sdpro.Customer & {
  merchantId?: string; //ログイン中の merchant 情報をもつ
  store?: sdpro.Store; //ログイン中の shop 情報をもつ
  currentStoreHourId?: string; //現在の営業時間帯の id をもつ、この id に該当する商品を表示
  checkedIn: boolean; // QRの初回読み込み時は checkedIn は false になる、スタンプを確認すると true に切り替わる
  checkedInAlchol: boolean; // （光フード限定機能）QRの初回読み込み時は checkedIn は false になる、確認すると true に切り替わる
  transactionHistories: TransHistory[];
  mCusPCards: { mCusPCardId: string; pCardId: string }[];
};

export type CustomerState = Customer & {
  dispatch: (action: CustomerAction) => void;
};

export const CustomerContext = createContext<CustomerState>({
  merchantId: undefined,
  store: undefined,
  createdAt: new Date(),
  currentStoreHourId: undefined,
  checkedIn: true,
  checkedInAlchol: true,
  deletedAt: null,
  documentStatus: 'active',
  lastVistedAt: null,
  lastOrderItems: null,
  lineId: null,
  mCustomerId: null,
  name: null,
  numOfVisit: 0,
  pictureUrl: null,
  stampExpiredAt: null,
  stampStartedAt: null,
  transactionHistories: [],
  updatedAt: new Date(),
  mCusPCards: [],
  dispatch: () => {
    throw new Error('Unimplemented!');
  },
});

export const useCustomer = (): CustomerState => useContext(CustomerContext);

type Props = {
  children: ReactNode;
};

export const CustomerProvider: VFC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(customerReducer, {
    checkedIn: true,
    checkedInAlchol: true,
    createdAt: new Date(),
    deletedAt: null,
    documentStatus: 'active',
    lastVistedAt: null,
    lastOrderItems: null,
    lineId: null,
    mCustomerId: null,
    name: null,
    numOfVisit: 0,
    pictureUrl: null,
    stampExpiredAt: null,
    stampStartedAt: null,
    transactionHistories: [],
    updatedAt: new Date(),
    mCusPCards: [],
  });

  return (
    <CustomerContext.Provider value={{ ...state, dispatch }}>
      {children}
    </CustomerContext.Provider>
  );
};
