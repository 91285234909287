import { ThemeOverride } from '@chakra-ui/react';

export const styles: ThemeOverride['styles'] = {
  global: {
    'html, body, #__next': {
      height: '100%',
      minHeight: '100%',
    },

    'html, body': {
      WebkitFontSmoothing: 'auto',
      touchAction: 'manipulation',
    },
  },
};
