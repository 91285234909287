import { Text, Flex, Box } from '@chakra-ui/react';
import { VFC } from 'react';
import { useStyle } from '../../../hooks/useStyle';
import { useTranslation } from 'react-i18next';

type Props = {
  onOpen: () => void;
};

export const ReorderButton: VFC<Props> = ({ onOpen }) => {
  const { cRepeatButton, fontFamily } = useStyle();
  const { t } = useTranslation();

  return (
    <Box
      onClick={onOpen}
      width={window.innerWidth * 0.25}
      height={window.innerHeight * 0.11}
      bg={cRepeatButton}
      borderWidth="2px"
      shadow="lg"
      borderRadius="lg"
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        h="100%"
      >
        <Text
          fontSize="md"
          fontWeight="bold"
          textShadow="text"
          textColor="white"
          fontFamily={fontFamily}
        >
          {t('okawari')}
        </Text>
      </Flex>
    </Box>
  );
};
