import { Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ItemsPage } from './view/components/items/items';
import { CartPage } from './view/components/cart/cart';
import { TransactionPage } from './view/components/transaction/tansaction';
import { NotFound } from './notFound';
import { PointCardsPage } from './view/components/pointCards/pointCards';
import { PointCardPage } from './view/components/pointCards/pointCard';
import { PointCardCreatePage } from './view/components/pointCards/pointCard.create';
import { SurveyPage } from './view/components/survey/survey';
import { SurveyDonePage } from './view/components/survey/survey.done';

export const routeHistory = createBrowserHistory();

export const route = {
  root: '/',
  items: '/items',
  cart: '/cart',
  history: '/history',
  pointCards: '/point_cards',
  survey: '/survey',
  surveyComplete: '/survey_complete',
};

export const routes = (
  <Switch>
    <Route
      exact
      path={`${route.root}:merchantId/:storeId/:transId${route.items}`}
      component={ItemsPage}
    />
    <Route
      exact
      path={`${route.root}:merchantId/:storeId/:transId${route.cart}`}
      component={CartPage}
    />
    <Route
      exact
      path={`${route.root}:merchantId/:storeId/:transId${route.history}`}
      component={TransactionPage}
    />
    <Route
      exact
      path={`${route.root}:merchantId/:storeId${route.pointCards}`}
      component={PointCardsPage}
    />
    <Route
      exact
      path={`${route.root}:merchantId/:storeId${route.pointCards}/:pointCardId`}
      component={PointCardPage}
    />
    <Route
      exact
      path={`${route.root}:merchantId/:storeId${route.pointCards}/:pointCardId/create`}
      component={PointCardCreatePage}
    />
    <Route
      exact
      path={`${route.root}:merchantId/:storeId${route.survey}/:surveyId`}
      component={SurveyPage}
    />
    <Route
      exact
      path={`${route.root}:merchantId/:storeId${route.surveyComplete}`}
      component={SurveyDonePage}
    />
    <Route path="*" component={NotFound} />
  </Switch>
);
