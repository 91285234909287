import { ComponentStyleConfig } from "@chakra-ui/react";

export const Select: ComponentStyleConfig = {
  baseStyle: {
    field: {
      _disabled: { backgroundColor: "gray.50" },
      _invalid: { border: "2px", borderColor: "red.500" },
      backgroundColor: "white",
      border: "3px",
      borderColor: "gray.100",
      color: "gray.900",
      fontSize: "md",
      minHeight: "12",
      maxHeight: "12",
    },
  },
};
