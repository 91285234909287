import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import { VFC } from 'react';
import { useStyle } from '../../../hooks/useStyle';

export const SurveyDonePage: VFC = () => {
  const { fontFamily } = useStyle();

  return (
    <Flex height={window.innerHeight} alignItems="center">
      <Flex direction="column" alignItems="center">
        <Box>
          <CheckCircleIcon color="green.300" boxSize={6} />
        </Box>
        <Text
          paddingTop={5}
          width={window.innerWidth}
          textAlign="center"
          color="grey"
          fontSize="small"
          fontWeight="bold"
          fontFamily={fontFamily}
        >
          アンケートを送信しました
        </Text>
      </Flex>
    </Flex>
  );
};
