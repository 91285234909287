import { Text, Flex, Box } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { route } from '../../../router';
import { VFC } from 'react';
import { useCustomer } from '../../../hooks/useCustomer';
import { useCart } from '../../../hooks/useCart';
import { useStyle } from '../../../hooks/useStyle';
import { useTranslation } from 'react-i18next';

export const OrderHistoryButton: VFC = () => {
  const history = useHistory();
  const { trans } = useCart();
  const { merchantId, store } = useCustomer();
  const { cPayButton, fontFamily } = useStyle();
  const { t } = useTranslation();

  return (
    <Box
      onClick={() =>
        history.push(
          `${route.root}${merchantId}/${store?.id}/${trans?.id ?? ''}${
            route.history
          }`
        )
      }
      marginX={window.innerWidth * 0.01}
      width={window.innerWidth * 0.25}
      height={window.innerHeight * 0.11}
      bg={cPayButton}
      borderWidth="2px"
      shadow="lg"
      borderRadius="lg"
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        h="100%"
      >
        <Text
          fontSize="md"
          fontWeight="bold"
          textShadow="text"
          textColor="white"
          fontFamily={fontFamily}
        >
          {t('history')}
        </Text>
        <Text
          fontSize="md"
          fontWeight="bold"
          textShadow="text"
          textColor="white"
          fontFamily={fontFamily}
        >
          {t('payment')}
        </Text>
      </Flex>
    </Box>
  );
};
