import { FC, useEffect, VFC } from 'react';
import { Text, Flex, chakra, HTMLChakraProps } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useCart } from '../../../hooks/useCart/useCart';
import { route } from '../../../router';
import { useCustomer } from '../../../hooks/useCustomer';
import { HTMLMotionProps, motion, useAnimation } from 'framer-motion';
import { useStyle } from '../../../hooks/useStyle';
import { useTranslation } from 'react-i18next';

export const CartButton: VFC = () => {
  const { items, trans } = useCart();
  const { merchantId, store } = useCustomer();
  const history = useHistory();
  const quantity = items.reduce((sum, element) => sum + element.quantity, 0);
  const { cOrderButton, fontFamily } = useStyle();
  const { t } = useTranslation();

  // animation用
  type Merge<P, T> = Omit<P, keyof T> & T;
  type MotionBoxProps = Merge<HTMLChakraProps<'div'>, HTMLMotionProps<'div'>>;
  const MotionBox: FC<MotionBoxProps> = motion(chakra.div);
  const controls = useAnimation();

  useEffect(() => {
    if (items.length > 0) {
      controls.start({
        backgroundColor: ['#FFFFFF', cOrderButton],
        scale: [1, 1, 1, 1, 1],
        rotate: [0, 0, 0, 0, 0],
        borderRadius: ['10%', '10%', '10%', '10%', '10%'],
      });
    }
    return function cleanup() {
      controls.stop();
    };
  }, [cOrderButton, controls, items.length]);

  return (
    <MotionBox
      onClick={() =>
        items.length > 0
          ? history.push(
              `${route.root}${merchantId}/${store?.id}/${trans?.id}${route.cart}`
            )
          : {}
      }
      marginX={window.innerWidth * 0.01}
      width={window.innerWidth * 0.25}
      height={window.innerHeight * 0.11}
      bg={quantity > 0 ? cOrderButton : '#FFFFFF'}
      borderWidth={quantity > 0 ? '2px' : 0}
      shadow={quantity > 0 ? 'lg' : 'sm'}
      borderRadius="lg"
      animate={controls}
      transition={{
        duration: 0.7,
        ease: 'easeInOut',
        times: [0],
        repeat: Infinity,
        repeatDelay: 0.7,
      }}
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        h="100%"
      >
        <Text
          fontSize="md"
          fontWeight="bold"
          textShadow="text"
          textColor="white"
          fontFamily={fontFamily}
        >
          {t('per2')}
          {quantity}
          {t('per0')}
        </Text>
        <Text
          fontSize="md"
          fontWeight="bold"
          textShadow="text"
          textColor="white"
          fontFamily={fontFamily}
        >
          {t('toOrder')}
        </Text>
      </Flex>
    </MotionBox>
  );
};
