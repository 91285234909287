import useSWR from 'swr';
import {
  useState,
  useCallback,
  useRef,
  VFC,
  useEffect,
  MutableRefObject,
} from 'react';
import {
  Box,
  Stack,
  NumberInput,
  NumberInputField,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  Flex,
  Alert,
  CloseButton,
  Text,
} from '@chakra-ui/react';
import { CustomerActionType, useCustomer } from '../../../hooks/useCustomer';
import { ItemCardSearched } from './itemCard';
import { getItems } from '../../../repositories/items';
import { CartButton } from './cartButton';
import { Item } from 'smartdishpro-scripts/ts_common';
import { OrderHistoryButton } from './orderHistoryButton';
import { SearchItemNumButton } from './searchItemNumButton';
import { ItemList } from './itemList';
import { Tabs } from './tabs';
import { useStyle } from '../../../hooks/useStyle';
import { isAndroid } from '../../../utils/device';
import useWindowSize from '../../../hooks/useDevice';
import { SwitchLanguageButton } from '../language/languageButton';
import { useTranslation } from 'react-i18next';
import { useAppTranslate } from '../../../hooks/useTranslation';
import {
  filterCategories,
  filterItems,
  filterItemsByCategory,
} from '../../../hooks/useItem/useItem';
import { ReorderButton } from './reorderButton';
import { TransactionHistory } from './transactionHistory';
import { useCart } from '../../../hooks/useCart';
import { getOrders } from '../../../repositories/order';
import { minutesTo } from '../../../utils/date';
import { ConfirmAlcholDialog } from './confirmAlcholDialog';

export const ItemsPage: VFC = () => {
  const { merchantId, store, currentStoreHourId } = useCustomer();
  const { trans } = useCart();
  const { data: i } = useSWR(
    ['/items', merchantId, store?.id, currentStoreHourId],
    (_, mId, sId, cId) => getItems(mId, sId, cId)
  );
  const { data: orders } = useSWR(
    ['/orders', merchantId, store?.id, trans?.id],
    (_, mId, sId) => getOrders(mId, sId, trans?.id ?? '')
  );
  const _orderedCourseItemIds =
    orders
      ?.filter((_o) => _o.orderItem.courseItem)
      .map((_o) => _o.orderItem.itemId) ?? [];

  const categories = filterCategories(store!, i!, _orderedCourseItemIds);
  const items = filterItems(i!, categories, _orderedCourseItemIds);

  const [itemsLayoutInfo, setItemsLayoutInfo] = useState<
    { length: number; position: number }[]
  >([]);

  const elementRef = useRef<HTMLDivElement>(null);
  const { cBg } = useStyle();
  const { itemHeight } = useWindowSize();
  const { translate } = useAppTranslate();

  useEffect(() => {
    let count = 0;

    setItemsLayoutInfo(
      categories.map((_c, _index) => {
        return {
          length: filterItemsByCategory(items, _c).length,
          position: (count +=
            itemHeight *
            (filterItemsByCategory(items, _c).length +
              filterItemsByCategory(items, _c).filter((_i) => _i.imageUrl)
                .length *
                2 +
              1)),
        };
      })
    );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items?.length]);

  const [categoryIndex, setCategoryIndex] = useState<number>(0);
  const [scrollingItems, setScrollingItems] = useState<boolean>(false);

  const onTapTab = useCallback(
    (index: number) => {
      setCategoryIndex(index);
      setScrollingItems(false);
      elementRef.current?.scrollTo(
        0,
        index === 0 ? 0 : itemsLayoutInfo[index - 1].position
      );
    },
    [itemsLayoutInfo]
  );

  const onScroll = useCallback(() => {
    const scrollTop = elementRef.current?.scrollTop ?? 0;

    for (let index = 0; index < itemsLayoutInfo.length; index++) {
      if (itemsLayoutInfo[index].position > scrollTop) {
        setCategoryIndex(index);
        setScrollingItems(true);
        break;
      }
    }
  }, [itemsLayoutInfo]);

  return (
    <Box
      w={window.innerWidth}
      h={isAndroid ? `${window.innerHeight}px` : window.innerHeight}
      bg={cBg}
    >
      <Stack pos="fixed" top="0" w="20%" h="100%" overflow="auto">
        <Tabs
          scrollingItems={scrollingItems}
          categoryIndex={categoryIndex}
          setCategoryIndex={onTapTab}
          categories={categories}
        />
      </Stack>
      <Box
        pos="fixed"
        top="0"
        left="20%"
        w="80%"
        h="100%"
        overflow="auto"
        alignItems="center"
        bg={cBg}
        ref={elementRef}
        onScroll={onScroll}
      >
        <Box display="flex" flexDirection="column">
          {categories
            ?.map((_c, _i) => (
              <ItemList
                key={_i}
                items={filterItemsByCategory(items, _c).map((_v) => {
                  return {
                    ..._v,
                    defaultOptionItems: _v.defaultCheckedItemIds
                      ?.filter(
                        (_itemId) =>
                          (i?.filter((_i) => _i.id === _itemId).length ?? 0) !==
                            0 &&
                          store!.optionGroups.filter(
                            (_group) =>
                              _group.optionItemIds.includes(_itemId) &&
                              _v.optionGroupIds.includes(_group.id)
                          ).length > 0
                      )
                      .map((_itemId) => {
                        const _item = i!.filter((_i) => _i.id === _itemId)[0];
                        const _optionGroup = store!.optionGroups.filter(
                          (_group) =>
                            _group.optionItemIds.includes(_itemId) &&
                            _v.optionGroupIds.includes(_group.id)
                        )[0];
                        return {
                          groupName: _optionGroup.groupName,
                          itemId: _item.id!,
                          itemName: _item.itemName,
                          itemNameForStore: _item.itemNameForStore,
                          pricePerItem: _item.price,
                          priceWTaxPerItem: _item.priceWStandardTax ?? 0,
                        };
                      }),
                  };
                })}
                categoryName={translate(_c.categoryName)}
              />
            ))
            .flat() ?? []}
          <Box h={isAndroid ? `${itemHeight * 12}px` : itemHeight * 12} />
        </Box>
      </Box>
      <Buttons items={i ?? []} sortedItems={items ?? []} />
    </Box>
  );
};

type Props = {
  items: Item[];
  sortedItems: Item[];
};

const Buttons: VFC<Props> = ({ items, sortedItems }) => {
  const {
    store,
    checkedIn,
    checkedInAlchol,
    dispatch: customerDispatch,
  } = useCustomer();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenReOrder,
    onOpen: onOpenReOrder,
    onClose: onCloseReOrder,
  } = useDisclosure();
  const cancelRef = useRef() as MutableRefObject<HTMLInputElement>;

  const firstFieldRef = useRef(null);
  const [searchingItems, setSearchingItems] = useState<Item[] | undefined>();
  const { t } = useTranslation();
  const { cBg, fontFamily, cTabBg } = useStyle();
  const { trans } = useCart();

  const handleOnChangeNum = useCallback(
    (str, valueAsNumber: number) => {
      setSearchingItems(
        sortedItems
          ?.filter(
            (item) =>
              item.itemNum != null &&
              item.itemNum >= valueAsNumber &&
              item.singleOrder
          )
          .sort((a, b) => (a.itemNum && b.itemNum ? a.itemNum - b.itemNum : 1))
      );
    },
    [sortedItems]
  );

  const {
    isOpen: isVisible,
    onClose: onCloseInitial,
    onOpen: onOpenInit,
  } = useDisclosure({
    defaultIsOpen: !checkedIn,
  });

  const {
    isOpen: isVisibleAlchol,
    onClose: onCloseInitialAlchol,
    onOpen: onOpenInitAlchol,
  } = useDisclosure({
    defaultIsOpen: !checkedInAlchol,
  });

  useEffect(() => {
    if (!checkedIn) {
      onOpenInit();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedIn]);

  useEffect(() => {
    if (!checkedInAlchol) {
      onOpenInitAlchol();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedInAlchol]);

  const onCloseInitialAlert = useCallback(() => {
    onCloseInitial();
    customerDispatch({
      type: CustomerActionType.SET_CHECKEDIN,
      payload: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCloseInitial]);

  const onCloseInitialAlcholAlert = useCallback(() => {
    onCloseInitialAlchol();
    customerDispatch({
      type: CustomerActionType.SET_CHECKEDIN_ALCHOL,
      payload: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCloseInitialAlchol]);

  return (
    <>
      {!!!trans?.courseFinishAt ? (
        <></>
      ) : (
        <>
          <Box pos="fixed" bottom="18%" left="20%" w="80%">
            <Flex
              justifyContent="flex-end"
              flexDirection="column"
              alignItems="center"
            >
              <Box
                width={window.innerWidth * 0.75}
                height={window.innerHeight * 0.04}
                bg={cTabBg}
                border="1px"
                borderColor={cBg}
                borderRadius="lg"
              >
                <Flex
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  h="100%"
                >
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    textShadow="text"
                    textColor="white"
                    fontFamily={fontFamily}
                  >
                    {minutesTo(trans!.courseFinishAt!) < 1
                      ? t('lasteOrderFinished')
                      : `${t('lastOrder')}${minutesTo(
                          trans!.courseFinishAt!
                        )}${t('minutes')}`}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </>
      )}
      <Box pos="fixed" bottom="5%" left="20%" w="80%">
        <Flex justifyContent="flex-end">
          <OrderHistoryButton />
          <ReorderButton onOpen={onOpenReOrder} />
          <CartButton />
        </Flex>
      </Box>
      <Box pos="fixed" top="1%" right="3%">
        <SwitchLanguageButton />
        <Box height="3" />
        <SearchItemNumButton onOpen={onOpen} />
      </Box>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        size="xs"
        scrollBehavior="inside"
      >
        <AlertDialogOverlay />
        <AlertDialogContent p="3" h={window.innerHeight * 0.6} bg={cBg}>
          <NumberInput w="100%" onChange={handleOnChangeNum}>
            <NumberInputField
              _focus={{
                color: 'black',
              }}
              placeholder={t('itemNumber')}
              borderWidth="2px"
              ref={firstFieldRef}
              fontSize="x-large"
              h={window.innerHeight * 0.1}
              fontFamily={fontFamily}
            />
          </NumberInput>
          <Box h="5" />
          <Stack h={window.innerHeight * 0.6} overflowY="auto">
            {searchingItems?.map((item, _i) => (
              <ItemCardSearched
                key={`${_i}${item.id}`}
                item={{
                  ...item,
                  defaultOptionItems: item.defaultCheckedItemIds
                    ?.filter(
                      (_itemId) =>
                        (items?.filter((_i) => _i.id === _itemId).length ??
                          0) !== 0
                    )
                    .map((_itemId) => {
                      const _item = items!.filter((_i) => _i.id === _itemId)[0];
                      const _optionGroup = store!.optionGroups.filter(
                        (_group) => _group.optionItemIds.includes(_itemId)
                      )[0];
                      return {
                        groupName: _optionGroup.groupName,
                        itemId: _item.id!,
                        itemName: _item.itemName,
                        itemNameForStore: _item.itemNameForStore,
                        pricePerItem: _item.price,
                        priceWTaxPerItem: _item.priceWStandardTax ?? 0,
                      };
                    }),
                }}
                onClose={onClose}
              />
            ))}
          </Stack>
        </AlertDialogContent>
      </AlertDialog>
      {isVisible ? (
        <Stack alignItems="center">
          <Alert
            status="info"
            bg="white"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
            w={
              isAndroid
                ? `${window.innerWidth * 0.9}px`
                : window.innerWidth * 0.9
            }
            h={
              isAndroid
                ? `${window.innerHeight * 0.5}px`
                : window.innerHeight * 0.5
            }
            alignSelf="center"
            borderRadius="md"
            boxShadow="md"
            marginTop={window.innerHeight * 0.2}
          >
            <CloseButton
              alignSelf="flex-end"
              position="relative"
              right={-1}
              top={-1}
              onClick={onCloseInitialAlert}
            />
            <TransactionHistory
              items={items}
              initialDialog={true}
              onClose={onCloseInitialAlert}
            />
          </Alert>
        </Stack>
      ) : isVisibleAlchol ? (
        <ConfirmAlcholDialog onClose={onCloseInitialAlcholAlert} />
      ) : (
        <></>
      )}
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onCloseReOrder}
        isOpen={isOpenReOrder}
        size="xs"
        scrollBehavior="inside"
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent
          p="3"
          h={
            isAndroid
              ? `${window.innerHeight * 0.6}px`
              : window.innerHeight * 0.6
          }
          bg={cBg}
        >
          <TransactionHistory
            items={items}
            initialDialog={false}
            onClose={onCloseReOrder}
          />
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
