import { CategoryGroup, Item, Store } from 'smartdishpro-scripts/ts_common';

// タブの横幅 window.innerWidth の20%
// 商品の横幅 window.innerWidthの80%
// 商品の縦幅 window.innerWidthの80% * 0.222
// 商品写真の縦幅 window.innerWidthの80% * 0.444

export const filterItems = (
  items: Item[],
  categories: CategoryGroup[],
  currentCourseItemIds: string[]
): Item[] => {
  return (
    categories
      ?.map(
        (_c) =>
          items
            ?.filter(
              (_i) =>
                _i.singleOrder &&
                _i.categoryIds.includes(_c.id) &&
                (_i.courseItemIds.length === 0 ||
                  _i.courseItemIds.some((_id) =>
                    currentCourseItemIds.includes(_id)
                  ))
            )
            // 重複削除
            ?.filter((x, i, arr) => arr.findIndex((y) => y.id === x.id) === i)
            ?.sort((a, b) => (a.itemNum ?? 0) - (b.itemNum ?? 0)) ?? []
      )
      .flat() ?? []
  );
};

export const filterItemsByCategory = (
  items: Item[],
  category: CategoryGroup
): Item[] => {
  return (
    items
      ?.filter((_i) => _i.categoryIds.includes(category.id))
      ?.filter((x, i, arr) => arr.findIndex((y) => y.id === x.id) === i)
      ?.sort((a, b) => (a.itemNum ?? 0) - (b.itemNum ?? 0)) ?? []
  );
};

export const filterCategories = (
  store: Store,
  items: Item[],
  currentCourseItemIds: string[]
): CategoryGroup[] => {
  return (
    store?.categoryGroups
      .sort((a, b) => a.sortNum - b.sortNum)
      .filter((_c) => _c.isPublic)
      .flat()
      ?.filter((_c) =>
        items?.some(
          (_i) =>
            _i.singleOrder &&
            _i.categoryIds.includes(_c.id) &&
            (_i.courseItemIds.length === 0 ||
              _i.courseItemIds.some((_id) =>
                currentCourseItemIds.includes(_id)
              ))
        )
      ) ?? []
  );
};
