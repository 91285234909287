import { useCustomer } from './useCustomer';

export const useStyle = (): {
  cBg: string;
  cOrderButton: string;
  cPayButton: string;
  cRepeatButton: string;
  cSelectedTabBg: string;
  cStampActiveBg: string;
  cStampBg: string;
  cTabBg: string;
  fontFamily: string;
} => {
  const { store } = useCustomer();

  return {
    cBg: `#${store?.layoutColors.cBg ?? 'FFFFFF'}`,
    cOrderButton: `#${store?.layoutColors.cOrderButton ?? 'DE2A28'}`,
    cPayButton: `#${store?.layoutColors.cPayButton ?? 'FDF273'}`,
    cRepeatButton: `#${store?.layoutColors.cRepeatButton ?? 'F19737'}`,
    cSelectedTabBg: `#${store?.layoutColors.cSelectedTabBg ?? '606060'}`,
    cStampActiveBg: `#${store?.layoutColors.cStampActiveBg ?? 'F56565'}`,
    cStampBg: `#${store?.layoutColors.cStampBg ?? '000000'}`,
    cTabBg: `#${store?.layoutColors.cTabBg ?? 'F2F2F2'}`,
    fontFamily: store?.fontFamily ?? 'notoSansJp',
  };
};
