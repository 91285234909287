import {
  Collection,
  MCusPCard,
  MCusPCardFromJson,
} from 'smartdishpro-scripts/ts_common';
import { db } from '../firebase';
import { createDocument } from './common';

const _mCusPCardsRef = (merchantId: string) =>
  db
    .collection(Collection.Merchants)
    .doc(merchantId)
    .collection(Collection.MCusPCards);

export const getMCusPCards = async (merchantId: string, mCusId: string) => {
  try {
    const snapshot = await _mCusPCardsRef(merchantId)
      .where('documentStatus', '==', 'active')
      .where('mCustomerId', '==', mCusId)
      .orderBy('createdAt', 'desc')
      .limit(200)
      .get();

    return snapshot.docs.map((_doc) => {
      return {
        id: _doc.id,
        value: MCusPCardFromJson(_doc.data({ serverTimestamps: 'estimate' })),
      };
    });
  } catch (error) {
    return [];
  }
};

export const getMCusPCard = async (merchantId: string, mCusPCardId: string) => {
  try {
    const snapshot = await _mCusPCardsRef(merchantId).doc(mCusPCardId).get();
    if (!snapshot.exists) {
      return null;
    }

    return MCusPCardFromJson(snapshot.data({ serverTimestamps: 'estimate' }));
  } catch (error) {
    return null;
  }
};

export const createMCusPCard = async (merchantId: string, doc: MCusPCard) => {
  return (await _mCusPCardsRef(merchantId).add(createDocument(doc))).id;
};
