import { Customer } from './useCustomer';
import * as sdpro from 'smartdishpro-scripts/ts_common';
import { TransHistory } from '../useHistory/useHistory';
import { MCusPCard } from 'smartdishpro-scripts/ts_common';

export enum CustomerActionType {
  SET_MERCHNTID = 'SET_MERCHNTID',
  SET_STORE = 'SET_STORE',
  SET_STOREHOUR_ID = 'SET_STOREHOUR_ID',
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
  SET_CHECKEDIN = 'SET_CHECKEDIN',
  SET_CHECKEDIN_ALCHOL = 'SET_CHECKEDIN_ALCHOL',
  SET_TRANS_HISTORIES = 'SET_TRANS_HISTORIES',
  SET_MCUSPCARDS = 'SET_MCUSPCARDS',
  ADD_MCUSPCARD = 'SET_MCUSPCARD',
}

export type CustomerAction =
  | {
      type: CustomerActionType.SET_MERCHNTID;
      payload: string;
    }
  | {
      type: CustomerActionType.SET_STORE;
      payload: sdpro.Store;
    }
  | {
      type: CustomerActionType.SET_STOREHOUR_ID;
      payload: string;
    }
  | {
      type: CustomerActionType.UPDATE_CUSTOMER;
      payload: sdpro.Customer;
    }
  | {
      type: CustomerActionType.SET_CHECKEDIN_ALCHOL;
      payload: boolean;
    }
  | {
      type: CustomerActionType.SET_CHECKEDIN;
      payload: boolean;
    }
  | {
      type: CustomerActionType.SET_TRANS_HISTORIES;
      payload: TransHistory[];
    }
  | {
      type: CustomerActionType.SET_MCUSPCARDS;
      payload: { value: MCusPCard; id: string }[];
    }
  | {
      type: CustomerActionType.ADD_MCUSPCARD;
      payload: { mCusPCardId: string; pCardId: string };
    };

export const customerReducer = (
  state: Customer,
  action: CustomerAction
): Customer => {
  switch (action.type) {
    case CustomerActionType.SET_MERCHNTID:
      return { ...state, merchantId: action.payload };
    case CustomerActionType.SET_STORE:
      return { ...state, store: action.payload };
    case CustomerActionType.SET_STOREHOUR_ID:
      return { ...state, currentStoreHourId: action.payload };
    case CustomerActionType.UPDATE_CUSTOMER:
      return {
        ...state,
        ...action.payload,
      };
    case CustomerActionType.SET_CHECKEDIN:
      return {
        ...state,
        checkedIn: action.payload,
      };
    case CustomerActionType.SET_CHECKEDIN_ALCHOL:
      return {
        ...state,
        checkedInAlchol: action.payload,
      };
    case CustomerActionType.SET_TRANS_HISTORIES:
      return {
        ...state,
        transactionHistories: action.payload,
      };
    case CustomerActionType.SET_MCUSPCARDS:
      return {
        ...state,
        mCusPCards: action.payload.map((_p) => {
          return { mCusPCardId: _p.id, pCardId: _p.value.pointCardId };
        }),
      };
    case CustomerActionType.ADD_MCUSPCARD:
      return {
        ...state,
        mCusPCards: state.mCusPCards.concat(action.payload),
      };
    default:
      return state;
  }
};
