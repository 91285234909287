import { VFC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { CustomerProvider } from './hooks/useCustomer';
import { CartProvider } from './hooks/useCart';
import { routes } from './router';
import { theme } from './view/theme';
import { AppInit } from './view/components/appInit';

export const App: VFC = () => {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <CartProvider>
          <CustomerProvider>
            <AppInit>{routes}</AppInit>
          </CustomerProvider>
        </CartProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
};
