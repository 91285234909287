import { Box, Flex, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState, VFC } from 'react';
import { CategoryGroup } from 'smartdishpro-scripts/ts_common';
import useWindowSize from '../../../hooks/useDevice';
import { useStyle } from '../../../hooks/useStyle';
import { useAppTranslate } from '../../../hooks/useTranslation';

type Props = {
  scrollingItems: boolean;
  categoryIndex: number;
  setCategoryIndex: (num: number) => void;
  categories: CategoryGroup[];
};

export const Tabs: VFC<Props> = ({
  scrollingItems,
  categoryIndex,
  setCategoryIndex,
  categories,
}) => {
  const { cSelectedTabBg, cTabBg, cBg, fontFamily } = useStyle();
  const { translate } = useAppTranslate();
  const [tabsLayoutInfo, setTabsLayoutInfo] = useState<(number | undefined)[]>(
    []
  );
  const elementTabsRef = useRef<HTMLDivElement>(null);
  const { tabHeight } = useWindowSize();

  useEffect(() => {
    setTabsLayoutInfo(
      categories?.map((_c, _index) => _index * tabHeight) ?? []
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories.length]);

  useEffect(() => {
    const _height = (tabsLayoutInfo[categoryIndex] ?? 0) - tabHeight * 3;

    if (scrollingItems) {
      elementTabsRef.current?.scrollTo(0, _height < 0 ? 0 : _height);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryIndex]);

  return (
    <Box
      sx={{
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      overflowY="auto"
      ref={elementTabsRef}
    >
      {categories.map((category, index) => (
        <Box
          key={`a${index}`}
          onClick={() => setCategoryIndex(index)}
          bg={categoryIndex === index ? cSelectedTabBg : cTabBg}
          w="100%"
          h={tabHeight}
          borderY="1px"
          borderRight="1px"
          borderColor={cBg}
          borderRightRadius="lg"
        >
          <Flex w="100%" h="100%" alignItems="center">
            <Text
              w="100%"
              fontSize="md"
              fontWeight="bold"
              textShadow="text"
              textColor="white"
              noOfLines={3}
              textAlign="center"
              marginX={1}
              fontFamily={fontFamily}
              style={{ whiteSpace: 'pre-line' }}
            >
              {translate(category.categoryName)}
            </Text>
          </Flex>
        </Box>
      ))}
    </Box>
  );
};
