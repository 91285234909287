import { RefObject, useCallback, useState, VFC } from 'react';
import {
  Box,
  Text,
  Flex,
  useToast,
  HStack,
  Button,
  Stack,
  Divider,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react';
import { useCart, CartActionType } from '../../../hooks/useCart';
import { Item, OptionGroup, OptionItem } from 'smartdishpro-scripts/ts_common';
import { useCustomer } from '../../../hooks/useCustomer';
import useSWR from 'swr';
import { getItems } from '../../../repositories/items';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { useStyle } from '../../../hooks/useStyle';
import { priceWSymbolItem } from '../../../utils/textFormat';
import { useTranslation } from 'react-i18next';
import { useAppTranslate } from '../../../hooks/useTranslation';
import useDevice from '../../../hooks/useDevice';
import { finishedCourseItemIds } from '../../../utils/converter';
import { getOrders } from '../../../repositories/order';

type Props = {
  item: Item & { defaultOptionItems: OptionItem[]; okawari: boolean };
  isOpen: boolean;
  cancelRef: RefObject<HTMLDivElement>;
  onClose: () => void;
  afterAddedCart: () => void;
};

export const ItemDialog: VFC<Props> = ({
  item,
  isOpen,
  cancelRef,
  onClose,
  afterAddedCart,
}) => {
  const { trans, dispatch: cartDispatch } = useCart();
  const { currentStoreHourId } = useCustomer();
  const { store, merchantId } = useCustomer();

  const { data: items } = useSWR(
    ['/items', merchantId, store?.id, currentStoreHourId],
    (_, mId, sId, cId) => getItems(mId, sId, cId)
  );

  const toast = useToast();
  const { t } = useTranslation();
  const { translate, optionError } = useAppTranslate();
  const { cOrderButton, fontFamily } = useStyle();
  const [quantity, setQuantity] = useState<number>(1);
  const [optionItems, setOptionItems] = useState<OptionItem[]>(
    item.defaultOptionItems
  );

  const handleOptionChange = useCallback(
    (group: OptionGroup, selectedItem: Item, checked: Boolean) => {
      if (checked) {
        if (selectedItem.soldOut) {
          toast({
            title: t('cannotSelectSoldOutItem'),
            status: 'error',
            position: 'bottom',
            isClosable: true,
          });
          return;
        }

        if (selectedItem.priceWStandardTax == null) {
          toast({
            title: t('itemIsNotEatInItem'),
            status: 'error',
            position: 'bottom',
            duration: 1000,
            isClosable: true,
          });
          return;
        }

        if (
          optionItems.filter((item) => item.groupName.ja === group.groupName.ja)
            .length >= group.max
        ) {
          toast({
            title: optionError(group),
            status: 'error',
            position: 'bottom',
            isClosable: true,
          });
          return;
        } else {
          setOptionItems([
            ...optionItems,
            {
              groupName: group.groupName,
              itemId: selectedItem.id ?? '',
              itemName: selectedItem.itemName,
              itemNameForStore: selectedItem.itemNameForStore,
              priceWTaxPerItem: selectedItem.priceWStandardTax ?? 0,
              pricePerItem: selectedItem.price,
            },
          ]);
        }
      } else {
        setOptionItems([
          ...optionItems.filter(
            (item) =>
              !(
                item.groupName.ja === group.groupName.ja &&
                item.itemId === selectedItem.id
              )
          ),
        ]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionItems]
  );

  const handleClickAddCart = useCallback(async () => {
    if (!store) return;
    if (item.soldOut) return;

    //必須選択のオプション
    const noSelectRequiredOptions = store.optionGroups
      ?.filter((g) => item.optionGroupIds.includes(g.id) && g.min > 0)
      ?.filter(
        (group) =>
          //選択したオプションの数と必須選択の最低選択数が同じかどうか
          optionItems.filter((item) => item.groupName.ja === group.groupName.ja)
            .length < group.min
      );

    if (noSelectRequiredOptions.length > 0) {
      toast({
        title: t('needToSelectOptions'),
        status: 'error',
        position: 'bottom',
        duration: 1000,
        isClosable: true,
      });
      return;
    }

    if (item.priceWStandardTax == null) {
      toast({
        title: t('itemIsNotEatInItem'),
        status: 'error',
        position: 'bottom',
        duration: 1000,
        isClosable: true,
      });
      return;
    }

    let _finishedCourseItemIds: string[] = [];
    if (trans?.courseFinishAt !== null) {
      const _orderHistories = await getOrders(
        merchantId!,
        store.id ?? '',
        trans?.id ?? ''
      );
      _finishedCourseItemIds = finishedCourseItemIds(_orderHistories);
    }
    if (
      item.courseItemIds.some((_id) => _finishedCourseItemIds.includes(_id))
    ) {
      toast({
        title: 'ラストオーダーが終了しているコース商品です',
        status: 'error',
        position: 'bottom',
        duration: 1000,
        isClosable: true,
      });
      return;
    }

    cartDispatch({
      type: CartActionType.ADD_ITEM,
      payload: {
        comment: null,
        courseItem: item.courseItem,
        deliveredAt: null,
        orderId: '',
        itemId: item.id ?? '',
        itemName: item.itemName,
        itemNameForStore: item.itemNameForStore,
        pricePerItem: item.price,
        priceWTaxPerItem: item.priceWStandardTax,
        itemTaxRate: 'standard',
        kitchenSectionNames: item.kitchenSectionNames,
        modifieds: [],
        okawari: item.okawari,
        optionItems: optionItems,
        paymentId: null,
        quantity: quantity,
        status: 'ordered',
        timeLimitedMin: item.timeLimitedMin,
      },
    });

    afterAddedCart();
    setOptionItems([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, item, optionItems, toast, cartDispatch, quantity]);

  return (
    <>
      <Modal
        scrollBehavior="inside"
        finalFocusRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size="xs"
      >
        <ModalOverlay />
        <ModalContent paddingX="3" overflow="auto">
          <Stack pos="inherit" top="0">
            <Stack pos="inherit" top="0">
              <Text
                textAlign="center"
                fontWeight="bold"
                fontSize="lg"
                noOfLines={3}
                paddingTop={2}
                fontFamily={fontFamily}
              >
                {translate(item.itemName)}
              </Text>
              <Box h="1px" />
              {item.description ? (
                <>
                  <Text
                    textAlign="center"
                    fontWeight="light"
                    fontSize="md"
                    noOfLines={5}
                    fontFamily={fontFamily}
                    whiteSpace="pre-line"
                  >
                    {item.description}
                  </Text>
                  <Box h="2px" />
                </>
              ) : (
                <></>
              )}
              {store?.optionGroups
                .filter((group) => item.optionGroupIds.includes(group.id))
                .sort(
                  (a, b) =>
                    (a.max === a.min ? 0 : 1) - (b.max === b.min ? 0 : 1)
                )
                ?.map((group) => {
                  return (
                    <Flex flexDirection="column" key={group.id}>
                      <Flex
                        marginTop="5"
                        justifyContent="space-between"
                        alignItems="flex-end"
                      >
                        <Text
                          w="80%"
                          fontSize="sm"
                          fontWeight="normal"
                          noOfLines={2}
                          fontFamily={fontFamily}
                          whiteSpace="pre-line"
                        >
                          {translate(group.groupName)}
                        </Text>
                        {group.min > 0 ? (
                          <Text
                            w="20%"
                            fontSize="xs"
                            fontWeight="light"
                            color="red.300"
                            fontFamily={fontFamily}
                          >{`${group.min}${t('needToSelect')}`}</Text>
                        ) : (
                          <Text
                            w="20%"
                            fontSize="xs"
                            fontWeight="light"
                            fontFamily={fontFamily}
                          >{`${group.max}${t('canSelect')}`}</Text>
                        )}
                      </Flex>
                      <Divider h="1" />
                      <Box h="1" />
                      <OptionItems
                        group={group}
                        items={items ?? []}
                        optionItems={optionItems}
                        handleOptionChange={(
                          group: OptionGroup,
                          selectedItem: Item,
                          checked: Boolean
                        ) => handleOptionChange(group, selectedItem, checked)}
                      />
                    </Flex>
                  );
                })}
              <HStack alignSelf="center" paddingY={3}>
                <Button
                  size="md"
                  _focus={{
                    ringColor: 'white',
                  }}
                  onClick={() =>
                    quantity > 1 ? setQuantity(quantity - 1) : null
                  }
                >
                  <AiOutlineMinus size={window.innerWidth * 0.06} />
                </Button>
                <Text
                  paddingX="5"
                  fontWeight="bold"
                  fontSize="x-large"
                  _focus={{
                    ringColor: 'white',
                  }}
                  fontFamily={fontFamily}
                >
                  {quantity}
                </Text>
                <Button
                  size="md"
                  _focus={{
                    ringColor: 'white',
                  }}
                  onClick={() =>
                    quantity < 20 ? setQuantity(quantity + 1) : null
                  }
                >
                  <AiOutlinePlus size={window.innerWidth * 0.06} />
                </Button>
              </HStack>
            </Stack>
            <Stack pos="sticky" bottom="0">
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                bgColor="#FFFFFF90"
                paddingY={2}
              >
                <Box
                  onClick={onClose}
                  w="30%"
                  h={window.innerHeight * 0.09}
                  alignSelf="self-end"
                  bg="#F2F2F2"
                  borderWidth="2px"
                  shadow="lg"
                  boxShadow="lg"
                  borderRadius="lg"
                >
                  <Flex
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    h="100%"
                  >
                    <Text
                      textAlign="center"
                      fontSize="md"
                      fontWeight="bold"
                      textShadow="text"
                      textColor="white"
                      fontFamily={fontFamily}
                    >
                      {t('back')}
                    </Text>
                  </Flex>
                </Box>
                <Box
                  onClick={handleClickAddCart}
                  w="68%"
                  h={window.innerHeight * 0.09}
                  alignSelf="center"
                  bg={cOrderButton}
                  borderWidth="2px"
                  shadow="lg"
                  boxShadow="lg"
                  borderRadius="lg"
                >
                  <Flex
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    h="100%"
                  >
                    <Text
                      textAlign="center"
                      fontSize="md"
                      fontWeight="bold"
                      textShadow="text"
                      textColor="white"
                      fontFamily={fontFamily}
                    >
                      {t('add')}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Stack>
          </Stack>
        </ModalContent>
      </Modal>
    </>
  );
};

type OptionItemsProps = {
  group: OptionGroup;
  items: Item[];
  optionItems: OptionItem[];
  handleOptionChange: (
    group: OptionGroup,
    selectedItem: Item,
    checked: Boolean
  ) => void;
};

const OptionItems: VFC<OptionItemsProps> = ({
  group,
  items,
  optionItems,
  handleOptionChange,
}) => {
  const { translate } = useAppTranslate();
  const { fontFamily } = useStyle();
  const { itemHeight } = useDevice();
  const onClick = useCallback(
    (item: Item, checked: Boolean) => handleOptionChange(group, item, checked),
    [group, handleOptionChange]
  );

  return (
    <Flex direction="column" alignItems="center">
      {group?.optionItemIds?.map((itemId) => {
        const oItem = items?.find((item) => item.id === itemId);
        const _v =
          optionItems.filter(
            (item) =>
              item.groupName.ja === group.groupName.ja && item.itemId === itemId
          ).length > 0;
        return oItem ? (
          <Box
            key={`${oItem.id}${itemId}${_v}`}
            w="100%"
            h={`${itemHeight}px`}
            onClick={() => onClick(oItem, !_v)}
            alignSelf="center"
          >
            <Box
              d="flex"
              h={`${itemHeight * 0.9}px`}
              alignSelf="center"
              borderWidth={window.innerHeight * 0.002}
              borderColor="gray.50"
              shadow={_v ? 'none' : 'sm'}
              bg="white"
              borderTopRadius="lg"
              borderBottomRadius="lg"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-evenly"
            >
              {_v ? (
                <Checkbox
                  w="8%"
                  size="lg"
                  defaultChecked
                  colorScheme="red"
                  css={`
                    > span:first-of-type {
                      box-shadow: unset;
                    }
                  `}
                  onChange={(e) => onClick(oItem, false)}
                />
              ) : oItem.soldOut ||
                optionItems.filter(
                  (item) => item.groupName.ja === group.groupName.ja
                ).length >= group.max ? (
                <Checkbox
                  w="8%"
                  size="lg"
                  colorScheme="red"
                  css={`
                    > span:first-of-type {
                      box-shadow: unset;
                    }
                  `}
                  isDisabled
                />
              ) : (
                <Checkbox
                  w="8%"
                  size="lg"
                  defaultChecked={false}
                  colorScheme="red"
                  css={`
                    > span:first-of-type {
                      box-shadow: unset;
                    }
                  `}
                  onChange={(e) => onClick(oItem, true)}
                />
              )}
              <Text
                w="63%"
                fontSize="s"
                fontStyle="normal"
                textAlign="start"
                noOfLines={1}
                fontFamily={fontFamily}
                paddingBottom={1}
              >
                {translate(oItem.itemName)}
              </Text>
              <Box w="20%" flexDirection="column" justifyContent="flex-start">
                <Text
                  fontSize="s"
                  fontStyle="normal"
                  textAlign="end"
                  noOfLines={1}
                  fontFamily={fontFamily}
                >
                  {priceWSymbolItem(oItem.price ?? 0)}
                </Text>
                <Text
                  fontSize="xx-small"
                  fontStyle="normal"
                  textAlign="end"
                  noOfLines={1}
                  fontFamily={fontFamily}
                >
                  ( {oItem.priceWStandardTax} )
                </Text>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box key={itemId}></Box>
        );
      })}
    </Flex>
  );
};
