import firebase from 'firebase';
import {
  CustomerFromJson,
  Customer,
  StampCardHistory,
  Collection,
} from 'smartdishpro-scripts/ts_common';
import { db } from '../firebase';
import { createDocument, updateDocument } from './common';

const _customersRef = (merchantId: string, storeId: string) =>
  db
    .collection(Collection.Merchants)
    .doc(merchantId)
    .collection(Collection.Stores)
    .doc(storeId)
    .collection(Collection.Customers);

export const getCustomer = async (
  merchantId: string,
  storeId: string,
  lineId: string
) => {
  try {
    const snapshot = await _customersRef(merchantId, storeId)
      .where('documentStatus', '==', 'active')
      .where('lineId', '==', lineId)
      .limit(1)
      .get();

    if (snapshot.empty) {
      return;
    }

    const data = snapshot.docs[0].data({ serverTimestamps: 'estimate' });
    return data ? CustomerFromJson(data, snapshot.docs[0].id) : undefined;
  } catch (error) {
    return undefined;
  }
};

export const getCustomerDoc = async (
  merchantId: string,
  storeId: string,
  customerId: string
) => {
  const snapshot = await _customersRef(merchantId, storeId)
    .doc(customerId)
    .get();

  return snapshot.exists
    ? CustomerFromJson(
        snapshot.data({ serverTimestamps: 'estimate' }),
        snapshot.id
      )
    : undefined;
};

export const createCustomer = async (
  merchantId: string,
  storeId: string,
  lineId: string,
  displayName: string,
  pictureUrl: string | undefined,
  stampExpiredAt: Date | null,
  stampStartedAt: Date | null
) => {
  const customer: Customer = {
    createdAt: new Date(),
    deletedAt: null,
    documentStatus: 'active',
    lastVistedAt: null,
    lastOrderItems: null,
    lineId: lineId,
    mCustomerId: null,
    name: displayName,
    numOfVisit: 0,
    pictureUrl: pictureUrl ?? null,
    stampExpiredAt: stampExpiredAt,
    stampStartedAt: stampStartedAt,
    updatedAt: new Date(),
  };

  await _customersRef(merchantId, storeId).add({
    ...createDocument(customer),
    stampExpiredAt:
      stampExpiredAt === null
        ? null
        : firebase.firestore.Timestamp.fromDate(stampExpiredAt),
    stampStartedAt:
      stampStartedAt === null
        ? null
        : firebase.firestore.Timestamp.fromDate(stampStartedAt),
  });

  return await getCustomer(merchantId, storeId, lineId);
};

export const updateCustomer = async (
  merchantId: string,
  storeId: string,
  customerId: string,
  name: string,
  pictureUrl: string | null,
  stampExpiredAt: Date | null,
  stampStartedAt: Date | null,
  history?: StampCardHistory
) => {
  const _json = updateDocument({
    name: name,
    pictureUrl: pictureUrl,
  });
  await _customersRef(merchantId, storeId)
    .doc(customerId)
    .update({
      ..._json,
      stampExpiredAt:
        stampExpiredAt === null
          ? null
          : firebase.firestore.Timestamp.fromDate(stampExpiredAt),
      stampStartedAt:
        stampStartedAt === null
          ? null
          : firebase.firestore.Timestamp.fromDate(stampStartedAt),
    });

  if (history) {
    await _customersRef(merchantId, storeId)
      .doc(customerId)
      .collection(Collection.StampCardHistories)
      .add({
        ...createDocument(history),
        stampExpiredAt:
          stampExpiredAt === null
            ? null
            : firebase.firestore.Timestamp.fromDate(history.stampExpiredAt),
        stampStartedAt:
          stampStartedAt === null
            ? null
            : firebase.firestore.Timestamp.fromDate(history.stampStartedAt),
      });
  }
};
