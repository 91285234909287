import { useCallback, VFC } from 'react';
import { Text, Button, Stack, Alert, Image } from '@chakra-ui/react';
import { useStyle } from '../../../hooks/useStyle';
import { isAndroid } from '../../../utils/device';
import { useAgreeAlcholTerms } from '../../../hooks/useInit/useAgreeAlcholTerms';

type Props = {
  onClose: () => void;
};

export const ConfirmAlcholDialog: VFC<Props> = ({ onClose }) => {
  const { fontFamily } = useStyle();
  const { onTapNotAgree } = useAgreeAlcholTerms();
  const onClickNotAgreed = useCallback(async () => {
    onClose();
    onTapNotAgree();
  }, [onClose, onTapNotAgree]);

  return (
    <Stack alignItems="center">
      <Alert
        status="info"
        bg="white"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        w={isAndroid ? `${window.innerWidth * 0.9}px` : window.innerWidth * 0.9}
        h={
          isAndroid ? `${window.innerHeight * 0.6}px` : window.innerHeight * 0.6
        }
        alignSelf="center"
        borderRadius="md"
        boxShadow="md"
        marginTop={window.innerHeight * 0.2}
      >
        <Image
          w={
            isAndroid ? `${window.innerWidth * 0.7}px` : window.innerWidth * 0.7
          }
          h={
            isAndroid
              ? `${window.innerHeight * 0.4}px`
              : window.innerHeight * 0.4
          }
          src={'/images/agree_alchol.jpg'}
          objectFit="contain"
          borderTopRadius="lg"
        />
        <Text
          paddingY="1"
          fontSize="xx-small"
          fontWeight="light"
          textColor="black"
          fontFamily={fontFamily}
        >
          ※選択せずに進んだ場合は同意するものとみなします
        </Text>
        <Button
          w={window.innerWidth * 0.6}
          height={window.innerHeight * 0.06}
          alignSelf="center"
          onClick={onClose}
          borderWidth="2px"
          bg="#FFFFFF"
          marginY={3}
        >
          <Text
            paddingY="2"
            fontSize="md"
            fontWeight="bold"
            textColor="black"
            fontFamily={fontFamily}
          >
            同意する
          </Text>
        </Button>
        <Button
          w={window.innerWidth * 0.6}
          height={window.innerHeight * 0.06}
          alignSelf="center"
          onClick={onClickNotAgreed}
          borderWidth="2px"
          bg="#FFFFFF"
        >
          <Text
            paddingY="2"
            fontSize="md"
            fontWeight="light"
            textColor="grey"
            fontFamily={fontFamily}
          >
            同意しない
          </Text>
        </Button>
      </Alert>
    </Stack>
  );
};
