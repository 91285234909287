import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MRASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();
db.enablePersistence({ synchronizeTabs: true });
export const auth = firebaseApp.auth();
export const storage = firebaseApp.storage();
export const functions = firebaseApp.functions('asia-northeast1');
export type result = firebase.auth.ConfirmationResult;
export const phoneProvider = firebase.auth.PhoneAuthProvider;

// For use emulator
// db.useEmulator('localhost', 8080);
// functions.useEmulator('localhost', 5000);
