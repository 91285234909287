import { Button } from '@chakra-ui/react';
import { VFC } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

type Props = {
  onOpen: () => void;
};

export const SearchItemNumButton: VFC<Props> = ({ onOpen }) => {
  return (
    <Button
      variant="link"
      onClick={onOpen}
      width="40px"
      height="40px"
      bg="white"
      shadow="lg"
      borderRadius="lg"
      d="flex"
      justifyContent="center"
      alignItems="center"
    >
      <AiOutlineSearch size={window.innerWidth * 0.06} color="gray.300" />
    </Button>
  );
};
