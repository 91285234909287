import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  Text,
  Flex,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { MutableRefObject, useCallback, useRef, VFC } from 'react';
import { Order } from 'smartdishpro-scripts/ts_common';
import { useStyle } from '../../../hooks/useStyle';
import { syoukei } from '../../../utils/calculator';
import { useTranslation } from 'react-i18next';
import { usePayment } from '../../../hooks/useTranslation/usePayment';
import { useCustomer } from '../../../hooks/useCustomer';
import { useAppTranslate } from '../../../hooks/useTranslation';

type Props = {
  orders: Order[] | undefined;
};

export const PaymentButton: VFC<Props> = ({ orders }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useDisclosure();
  const cancelRef = useRef() as MutableRefObject<HTMLInputElement>;
  const { cPayButton, fontFamily } = useStyle();
  const { t } = useTranslation();
  const { onTapPayment } = usePayment();
  const { store } = useCustomer();
  const { translate } = useAppTranslate();
  const onClick = useCallback(async () => {
    onCloseConfirm();
    onOpen();
    await new Promise((resolve) => setTimeout(resolve, 1000));
    onTapPayment();
  }, [onCloseConfirm, onOpen, onTapPayment]);

  return (
    <>
      <Button
        onClick={onOpenConfirm}
        justifyContent="center"
        pos="inherit"
        p="6"
        w={window.innerWidth * 0.6}
        h={window.innerHeight * 0.11}
        borderWidth="2px"
        bg={cPayButton}
        colorScheme={cPayButton}
        shadow="lg"
        borderRadius="lg"
      >
        <Flex flexDirection="column" justifyContent="space-evenly">
          {store?.displayTotalPrice ? (
            <Flex justifyContent="flex-end">
              <Text
                fontSize="md"
                fontWeight="bold"
                textShadow="text"
                textColor="white"
                fontFamily={fontFamily}
              >
                {t('sum')}
              </Text>
              <Box w={window.innerWidth * 0.01} />
              <Text
                fontSize="md"
                fontWeight="bold"
                textShadow="text"
                textColor="white"
                fontFamily={fontFamily}
              >
                ¥
                {syoukei(
                  orders
                    ?.filter((order) => order.orderItem.quantity > 0)
                    .map((_o) => _o.orderItem) ?? []
                )}
                ({t('includeTax')})
              </Text>
            </Flex>
          ) : (
            <></>
          )}
          <Text
            fontSize="md"
            fontWeight="bold"
            textShadow="text"
            textColor="white"
            fontFamily={fontFamily}
          >
            {t('pay')}
          </Text>
        </Flex>
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseConfirm}
        isOpen={isOpenConfirm}
        isCentered
        size="xs"
      >
        <AlertDialogOverlay />
        <AlertDialogContent p="3" alignItems="center">
          <Text
            textAlign="center"
            fontWeight="bold"
            fontSize="md"
            fontFamily={fontFamily}
          >
            {t('confirmPayment')}
          </Text>
          <Box h={window.innerHeight * 0.03} />
          <Flex justifyContent="center" direction="row" w="100%">
            <Button
              alignSelf="center"
              p="3"
              onClick={onCloseConfirm}
              w="45%"
              h={window.innerHeight * 0.08}
              borderWidth="2px"
              bg="#F2F2F2"
              shadow="lg"
              borderRadius="lg"
            >
              <Text
                fontSize="md"
                fontWeight="bold"
                textShadow="text"
                textColor="white"
                fontFamily={fontFamily}
              >
                {t('back')}
              </Text>
            </Button>
            <Box w={window.innerWidth * 0.007} />
            <Button
              onClick={onClick}
              justifyContent="center"
              p="3"
              w="45%"
              h={window.innerHeight * 0.08}
              borderWidth="2px"
              bg={cPayButton}
              colorScheme={cPayButton}
              shadow="lg"
              borderRadius="lg"
            >
              <Text
                fontSize="md"
                fontWeight="bold"
                textShadow="text"
                textColor="white"
                fontFamily={fontFamily}
              >
                {t('yes')}
              </Text>
            </Button>
          </Flex>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size="xs"
      >
        <AlertDialogOverlay />
        <AlertDialogContent p="3">
          <Text
            textAlign="center"
            fontWeight="bold"
            fontSize="md"
            fontFamily={fontFamily}
          >
            {translate(store!.messageOnPaymentButton)}
          </Text>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
