import {
  Collection,
  PointCard,
  PointCardFromJson,
} from 'smartdishpro-scripts/ts_common';
import firebase from 'firebase/app';
import { db } from '../firebase';

const _pointCardRef = (merchantId: string) =>
  db
    .collection(Collection.Merchants)
    .doc(merchantId)
    .collection(Collection.PointCards);

export const getPointCards = async (merchantId: string) => {
  try {
    const snapshot = await _pointCardRef(merchantId)
      .where('documentStatus', '==', 'active')
      .limit(200)
      .get();

    return snapshot.docs.map((_doc) => {
      return {
        id: _doc.id,
        value: FromJson(_doc.data({ serverTimestamps: 'estimate' })),
      };
    });
  } catch (error) {
    return [];
  }
};

export const getPointCard = async (merchantId: string, pointCardId: string) => {
  try {
    const snapshot = await _pointCardRef(merchantId).doc(pointCardId).get();

    if (!snapshot.exists) {
      return null;
    }

    return PointCardFromJson(snapshot.data({ serverTimestamps: 'estimate' })!);
  } catch (error) {
    return null;
  }
};

export const FromJson = (data: firebase.firestore.DocumentData): PointCard => {
  return {
    availableStoreIds: data.availableStoreIds,
    caution: data.caution,
    createdAt: data.createdAt.toDate(),
    documentStatus: data.documentStatus,
    deletedAt: data.deletedAt?.toDate() ?? null,
    expireAt: data.expireAt.toDate(),
    imageUrl: data.imageUrl,
    title: data.title,
    updatedAt: data.updatedAt.toDate(),
  };
};
