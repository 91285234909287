import { Box, Flex, Text } from '@chakra-ui/react';
import { useCallback, VFC } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { useCustomer } from '../../../hooks/useCustomer';
import { getMCusPCards } from '../../../repositories/mCusPCard';
import { getPointCards } from '../../../repositories/pointCard';
import { route } from '../../../router';
import '@fontsource/noto-sans-jp/900.css';
import { isAndroid } from '../../../utils/device';

export const PointCardsPage: VFC = () => {
  const history = useHistory();

  const { merchantId, store, mCustomerId } = useCustomer();

  const { data: mCusPCards } = useSWR(
    [`/mCusPCards`, merchantId, mCustomerId],
    () => getMCusPCards(merchantId ?? '', mCustomerId ?? '')
  );

  const { data: pointCards } = useSWR([`/pointCards`, merchantId], () =>
    getPointCards(merchantId ?? '')
  );

  const onClick = useCallback(
    (id: string) => {
      history.push(
        `${route.root}${merchantId}/${store?.id}${route.pointCards}/${id}`
      );
    },
    [history, merchantId, store?.id]
  );

  return (
    <Flex
      direction="column"
      backgroundColor="gray.100"
      minH={isAndroid ? `${window.innerHeight}px` : window.innerHeight}
    >
      <Text
        textAlign="center"
        color="black"
        paddingY={10}
        fontSize="lg"
        fontWeight={800}
        fontFamily="notoSansJp"
      >
        あなたのもっているカード
      </Text>
      <Box paddingX={15}>
        {mCusPCards?.map((_card) => {
          const _pointCard =
            (
              pointCards?.filter(
                (card) => card.id === _card.value.pointCardId
              ) ?? []
            ).length > 0
              ? (pointCards?.filter(
                  (card) => card.id === _card.value.pointCardId
                ) ?? [])[0]
              : null;

          return (
            <Flex
              key={_card.id}
              w="100%"
              padding={3}
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              borderWidth={window.innerHeight * 0.002}
              borderColor="lightgray"
              shadow="sm"
              bg="white"
              borderRadius="lg"
              onClick={() => {
                onClick(_pointCard?.id ?? '');
              }}
            >
              <Text
                fontWeight="semibold"
                fontSize="large"
                noOfLines={2}
                paddingLeft={window.innerHeight * 0.015}
              >
                {_pointCard?.value.title ?? ''}
              </Text>
              <Text fontWeight="semibold" fontSize="large" textAlign="end">
                {_card.value.point} ポイント
              </Text>
            </Flex>
          );
        })}
      </Box>
    </Flex>
  );
};
