import { useTranslation } from 'react-i18next';
import { OptionGroup, I18nString } from 'smartdishpro-scripts/ts_common';

export const useAppTranslate = () => {
  const { i18n, t } = useTranslation();

  const translate = (value: I18nString) => {
    if (i18n.language === 'en') {
      return value.en ?? value.ja;
    } else if (i18n.language === 'zhCN') {
      return value.zhCn ?? value.en ?? value.ja;
    } else if (i18n.language === 'zhTW') {
      return value.zhTw ?? value.en ?? value.ja;
    } else if (i18n.language === 'ko') {
      return value.ko ?? value.en ?? value.ja;
    } else {
      return value.ja;
    }
  };

  const optionError = (group: OptionGroup) => {
    const convertGroupName = () => {
      if (i18n.language === 'en') {
        return group.groupName.en ?? group.groupName.ja;
      } else if (i18n.language === 'zhCN') {
        return group.groupName.zhCn ?? group.groupName.en ?? group.groupName.ja;
      } else if (i18n.language === 'zhTW') {
        return group.groupName.zhTw ?? group.groupName.en ?? group.groupName.ja;
      } else if (i18n.language === 'ko') {
        return group.groupName.ko ?? group.groupName.en ?? group.groupName.ja;
      } else {
        return '';
      }
    };
    if (i18n.language === 'en') {
      return `${t('cannotSelectAnyMore')} ${group.max} ${t(
        'per0'
      )} in ${convertGroupName()}`;
    } else if (i18n.language === 'zhCN') {
      return `${convertGroupName()}${t('cannotSelectAnyMore')}${group.max}${t(
        'per0'
      )}`;
    } else if (i18n.language === 'zhTW') {
      return `${convertGroupName()}${t('cannotSelectAnyMore')}${group.max}${t(
        'per0'
      )}`;
    } else if (i18n.language === 'ko') {
      return `${convertGroupName()} ${t('is')} ${group.max}${t(
        'cannotSelectAnyMore'
      )}`;
    } else {
      return `${group.groupName.ja}${t('is')}${group.max + 1}${t(
        'cannotSelectAnyMore'
      )}`;
    }
  };

  return { translate, optionError };
};
