import { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
  sizes: {
    card: {
      w: '100%',
    },
    order: {
      w: '80%',
    },
  },
  variants: {
    'with-shadow': {
      backgroundColor: 'white',
      boxShadow: 'lg',
    },
  },
  baseStyle: {
    _focus: {
      boxShadow: 'none',
    },
  },
};
