import { Collection, SurveyFromJson } from 'smartdishpro-scripts/ts_common';
import { db } from '../firebase';

const _surveysRef = (merchantId: string) =>
  db
    .collection(Collection.Merchants)
    .doc(merchantId)
    .collection(Collection.Surveys);

export const getSurvey = async (merchantId: string, surveyId: string) => {
  try {
    const snapshot = await _surveysRef(merchantId).doc(surveyId).get();

    if (!snapshot.exists) {
      return null;
    }

    return SurveyFromJson(snapshot.data({ serverTimestamps: 'estimate' }));
  } catch (error) {
    return null;
  }
};
