/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Spinner, Stack, Text } from '@chakra-ui/react';
import { useEffect, VFC, ReactNode } from 'react';
import packageJson from '../../../package.json';
import { useCustomer } from '../../hooks/useCustomer/useCustomer';
import { useCart } from '../../hooks/useCart/useCart';
import { Helmet } from 'react-helmet';
import { useInit } from '../../hooks/useInit/useInit';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useStyle } from '../../hooks/useStyle';

type Props = {
  children: ReactNode;
};

export const AppInit: VFC<Props> = ({ children }) => {
  const { init, isLoading, error } = useInit();

  const customer = useCustomer();
  const { trans } = useCart();
  const { t } = useTranslation();
  const { fontFamily } = useStyle();

  useEffect(() => {
    init();
    return () => {};
  }, []);

  if (
    isLoading ||
    error ||
    !!!customer.id ||
    !!!customer.merchantId ||
    !!!customer.store
  ) {
    const qrPayments = trans?.payments?.filter(
      (_p) =>
        _p.qrCodeTextForSelfRegi != null &&
        new Date().getTime() <
          new Date(_p.createdAt.setHours(_p.createdAt.getHours() + 5)).getTime()
      /// QR生成して5時間以内は有効
    );

    return (
      <Stack w={window.innerWidth} h={window.innerHeight} bg="gray.300">
        <Flex
          justify="center"
          flexDirection="column"
          height="100vh"
          justifyContent="space-evenly"
        >
          {isLoading ? (
            <Spinner alignSelf="center" />
          ) : error && (qrPayments?.length ?? 0) > 0 ? (
            <Box
              h={window.innerHeight * 0.8}
              w={window.innerWidth}
              flexDirection="column"
              overflow="auto"
            >
              {qrPayments?.map((_qr, _i) => (
                <Flex
                  key={`${_qr.qrCodeTextForSelfRegi}${_i}`}
                  justifyContent="center"
                  paddingY="10"
                  direction="column"
                  alignItems="center"
                >
                  <QRCode
                    id="qrCodeEl"
                    size={150}
                    value={_qr.qrCodeTextForSelfRegi!}
                  />
                  <Text paddingY={1} fontFamily={fontFamily}>
                    ¥{_qr.paidPrice}
                  </Text>
                  <Text
                    paddingX="10"
                    textAlign="center"
                    color="grey"
                    fontSize="small"
                    fontWeight="bold"
                    fontFamily={fontFamily}
                  >
                    {t('canPayBySelfRegi')}
                  </Text>
                </Flex>
              ))}
            </Box>
          ) : (
            <Text
              padding="10"
              textAlign="center"
              color="grey"
              fontSize="small"
              fontFamily={fontFamily}
            >
              {error}
            </Text>
          )}
          <Text
            textAlign="center"
            color="grey"
            fontSize="xx-small"
            fontFamily={fontFamily}
          >
            {t('version')} {packageJson.version}
          </Text>
        </Flex>
      </Stack>
    );
  }

  return (
    <div className="application">
      <Helmet
        title={customer.store?.storeName}
        titleAttributes={{ lang: 'ja' }}
        meta={[{ name: 'description', content: 'テーブルオーダー' }]}
      />
      {children}
    </div>
  );
};
