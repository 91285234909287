import { Store, StoreHour } from 'smartdishpro-scripts/ts_common';
import { getStore } from '../../repositories/stores';

export const checkStoreOpen = async (
  merchantId: string,
  storeId: string
): Promise<{ storeHourId: string | null; store?: Store }> => {
  const store = await getStore(merchantId, storeId);
  return { storeHourId: check(store!.storeHours), store };
};

export const check = (storeHours: StoreHour[]): string | null => {
  let storeHourId: string | null = null;

  storeHours.forEach((hour) => {
    let currentStartAt: number | null = null;
    let currentEndAt: number | null = null;
    let lastStartAt: number | null = null;
    let lastEndAt: number | null = null;
    const _t = new Date();
    const day = _t.getDay();
    switch (day) {
      case 0: //Sunday
        currentStartAt = hour.start0At;
        currentEndAt = hour.end0At;
        lastStartAt = hour.start6At;
        lastEndAt = hour.end6At;
        break;
      case 1: //Monday
        currentStartAt = hour.start1At;
        currentEndAt = hour.end1At;
        lastStartAt = hour.start0At;
        lastEndAt = hour.end0At;
        break;
      case 2: //Tuesday
        currentStartAt = hour.start2At;
        currentEndAt = hour.end2At;
        lastStartAt = hour.start1At;
        lastEndAt = hour.end1At;
        break;
      case 3: //Wednesday
        currentStartAt = hour.start3At;
        currentEndAt = hour.end3At;
        lastStartAt = hour.start2At;
        lastEndAt = hour.end2At;
        break;
      case 4: //Thursday
        currentStartAt = hour.start4At;
        currentEndAt = hour.end4At;
        lastStartAt = hour.start3At;
        lastEndAt = hour.end3At;
        break;
      case 5: //Friday
        currentStartAt = hour.start5At;
        currentEndAt = hour.end5At;
        lastStartAt = hour.start4At;
        lastEndAt = hour.end4At;
        break;
      case 6: //Saturday
        currentStartAt = hour.start6At;
        currentEndAt = hour.end6At;
        lastStartAt = hour.start5At;
        lastEndAt = hour.end5At;
        break;
      default:
    }

    const now = _t.getTime();
    _t.setHours(0);
    _t.setMinutes(0);
    const startToday = _t.getTime();
    const seconds = Math.ceil(now / 1000) - Math.ceil(startToday / 1000);

    if (currentStartAt != null && currentEndAt != null) {
      if (
        currentStartAt < currentEndAt &&
        currentStartAt <= seconds &&
        currentEndAt >= seconds
      ) {
        storeHourId = hour.id;
      }
      if (currentStartAt > currentEndAt && currentStartAt <= seconds) {
        storeHourId = hour.id;
      }
    }

    if (lastStartAt != null && lastEndAt != null) {
      if (lastStartAt > lastEndAt && seconds <= lastEndAt) {
        storeHourId = hour.id;
      }
    }
  });
  return storeHourId;
};
