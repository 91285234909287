import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // public/localesに辞書ファイルあり
    //   en：英語
    //   ja：日本語
    //   ko：韓国語
    //   zhCN：簡体字（Simplified Chinese）
    //   zhTW：繁体字（Traditional Chinese）
    ns: ['translation'],
    lng: 'ja',
    fallbackLng: 'ja',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });
