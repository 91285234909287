import { ThemeOverride } from '@chakra-ui/react';
import '@fontsource/dela-gothic-one';
import '@fontsource/kaisei-opti';
import '@fontsource/kaisei-tokumin';
import '@fontsource/kiwi-maru';
import '@fontsource/klee-one';
import '@fontsource/kosugi';
import '@fontsource/kosugi-maru';
import '@fontsource/mochiy-pop-one';
import '@fontsource/noto-sans-jp';
import '@fontsource/noto-serif-jp';
import '@fontsource/open-sans';
import '@fontsource/sawarabi-mincho';
import '@fontsource/yomogi';
import '@fontsource/yuji-syuku';
import '@fontsource/yusei-magic';
import '@fontsource/zen-antique';

export const fonts: ThemeOverride['fonts'] = {
  heading: `"Noto Sans JP", sans-serif`,
  body: `"Noto Sans JP", sans-serif`,

  // https://fontsource.org/fonts/dela-gothic-one
  delaGothicOne: `"Dela Gothic One", cursive`,

  // https://fontsource.org/fonts/kaisei-opti
  kaiseiOpti: `"Kaisei Opti", serif`,

  // https://fontsource.org/fonts/kaisei-tokumin
  kaiseiTokumin: `"Kaisei Tokumin", serif`,

  // https://fontsource.org/fonts/kiwi-maru
  kiwiMaru: `"Kiwi Maru", serif`,

  // https://fontsource.org/fonts/klee-one
  kleeOne: `"Klee One", cursive`,

  // https://fontsource.org/fonts/kosugi
  kosugi: `"Kosugi", sans-serif`,

  // https://fontsource.org/fonts/kosugi-maru
  kosugiMaru: `"Kosugi Maru", sans-serif`,

  // https://fontsource.org/fonts/mochiy-pop-one
  mochiyPopOne: `"Mochiy Pop One", sans-serif`,

  // https://fontsource.org/fonts/noto-sans-jp
  notoSansJp: `"Noto Sans JP", sans-serif`,

  // https://fontsource.org/fonts/noto-serif-jp
  notoSerifJp: `"Noto Serif JP", serif`,

  // https://fontsource.org/fonts/open-sans
  openSans: `"Open Sans", sans-serif`,

  // https://fontsource.org/fonts/sawarabi-mincho
  sawarabiMincho: `"Sawarabi Mincho", serif`,

  // https://fontsource.org/fonts/yomogi
  yomogi: `"Yomogi", cursive`,

  // https://fontsource.org/fonts/yuji-syuku
  yujiSyuku: `"Yuji Syuku", serif`,

  // https://fontsource.org/fonts/yusei-magic
  yuseiMagic: `"Yusei Magic", sans-serif`,

  // https://fontsource.org/fonts/zen-antique
  zenAntique: `"Zen Antique", serif`,
};
