import { extendTheme } from '@chakra-ui/react';
import { components } from './components';
import { borders } from './foundations/borders';
import { colors } from './foundations/colors';
import { fonts } from './foundations/fonts';
import { shadows } from './foundations/shadows';
import { styles } from './foundations/styles';

export const theme = extendTheme({
  borders,
  components,
  shadows,
  styles,
  colors,
  fonts,
});
