import { StoreFromJson, Collection } from 'smartdishpro-scripts/ts_common';
import { db } from '../firebase';

const _storesRef = (merchantId: string) =>
  db
    .collection(Collection.Merchants)
    .doc(merchantId)
    .collection(Collection.Stores);

export const getStore = async (merchantId: string, storeId: string) => {
  const snapshot = await _storesRef(merchantId).doc(storeId).get();
  const data = snapshot.data({ serverTimestamps: 'estimate' });
  return snapshot.exists && data ? StoreFromJson(data, snapshot.id) : undefined;
};
