import { ComponentStyleConfig } from "@chakra-ui/react";

export const Input: ComponentStyleConfig = {
  baseStyle: {
    field: {
      _disabled: { backgroundColor: "gray.50" },
      _invalid: { border: "2px", borderColor: "red.500" },
      backgroundColor: "white",
      border: "3px",
      borderColor: "gray.100",
      color: "gray.900",
      fontSize: "md",
      height: "12",
    },
    addon: {
      border: "3px",
      borderColor: "gray.100",
      borderLeftRadius: "base",
      borderRight: "none",
      borderRightRadius: "none",
      color: "gray.500",
      fontSize: "md",
      height: 12,
    },
  },
};
