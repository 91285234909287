import { useToast } from '@chakra-ui/toast';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type PromiseFn = (...args: any[]) => Promise<any>;

type PromiseValue<P extends PromiseFn> = ReturnType<P> extends Promise<infer U>
  ? U
  : never;

type UseFetcherReturnType<Fn extends PromiseFn> = {
  value: PromiseValue<Fn> | undefined;
  isLoading: boolean;
  execute: (...args: Parameters<Fn>) => Promise<void>;
};

export const useAsync = <Fn extends PromiseFn>(
  fetcher: Fn,
  defaultValue?: PromiseValue<Fn>
): UseFetcherReturnType<Fn> => {
  const [value, setValue] = useState<PromiseValue<Fn> | undefined>(
    defaultValue
  );
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();

  const execute = async (...args: Parameters<Fn>) => {
    setIsLoading(true);

    try {
      const value = await fetcher(...args);
      setValue(value);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast({
        title: t('error'),
        status: 'error',
        position: 'bottom',
        isClosable: true,
      });
    }
  };

  return { value, isLoading, execute };
};
