import { useEffect, useState } from 'react';

export default function useWindowSize() {
  const isSSR = typeof window !== 'undefined';
  const [height, setHeight] = useState(isSSR ? window.innerHeight : 800);
  const [width, setWidth] = useState(isSSR ? window.innerWidth : 400);
  const [itemWidth, setItemWidth] = useState(
    isSSR ? Math.round(window.innerWidth * 0.75) : 300
  );
  const [itemHeight, setItemHeight] = useState(
    isSSR ? Math.round(itemWidth * 0.22222) : 100
  );
  const [tabHeight, setTabHeight] = useState(
    isSSR ? window.innerHeight * 0.13 : 100
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (isSSR) {
        setHeight(window.innerHeight);
        setWidth(window.innerWidth);
        setItemWidth(Math.round(window.innerWidth * 0.75));
        setItemHeight(Math.round(itemWidth * 0.22222));
        setTabHeight(window.innerHeight * 0.13);
      }
    });

    return () => {
      window.removeEventListener('resize', () => {
        setHeight(window.innerHeight);
        setWidth(window.innerWidth);
        setItemWidth(Math.round(window.innerWidth * 0.75));
        setTabHeight(window.innerHeight * 0.13);
      });
    };
  }, [isSSR, itemWidth]);
  return { height, width, itemWidth, itemHeight, tabHeight };
}
