import { useRef, VFC, MutableRefObject } from 'react';
import {
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  Button,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BsTranslate } from 'react-icons/bs';
import { useStyle } from '../../../hooks/useStyle';

export const SwitchLanguageButton: VFC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef() as MutableRefObject<HTMLInputElement>;
  const { i18n } = useTranslation();
  const { fontFamily } = useStyle();

  return (
    <>
      <Button
        variant="link"
        onClick={onOpen}
        width="40px"
        height="40px"
        bg="white"
        shadow="lg"
        borderRadius="lg"
        d="flex"
        justifyContent="center"
        alignItems="center"
      >
        <BsTranslate size={window.innerWidth * 0.06} color="gray.300" />
      </Button>
      <AlertDialog
        size="xs"
        isCentered
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
      >
        <AlertDialogOverlay />
        <AlertDialogContent p="3" w={window.innerWidth * 0.5}>
          <RadioGroup
            onChange={(lng) => i18n.changeLanguage(lng)}
            value={i18n.language}
            d="flex"
            flexDirection="column"
            colorScheme="gray"
          >
            <Radio
              value="ja"
              _focus={{
                ringColor: 'white',
              }}
              size="lg"
              fontFamily={fontFamily}
            >
              日本語
            </Radio>
            <Radio
              value="en"
              _focus={{
                ringColor: 'white',
              }}
              size="lg"
              fontFamily={fontFamily}
            >
              English
            </Radio>
            <Radio
              value="ko"
              _focus={{
                ringColor: 'white',
              }}
              size="lg"
              fontFamily={fontFamily}
            >
              한국어
            </Radio>
            <Radio
              value="zhCN"
              _focus={{
                ringColor: 'white',
              }}
              size="lg"
              fontFamily={fontFamily}
            >
              简体字
            </Radio>
            <Radio
              value="zhTW"
              _focus={{
                ringColor: 'white',
              }}
              size="lg"
              fontFamily={fontFamily}
            >
              繁體中文
            </Radio>
          </RadioGroup>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
