import { Button, Text, Box, Stack, Flex } from '@chakra-ui/react';
import { VFC } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyle } from '../../../hooks/useStyle';
import { CartItems } from './cartItems';
import { SendButton } from './sendButton';
import { useTranslation } from 'react-i18next';

export const CartPage: VFC = () => {
  const history = useHistory();
  const { cBg, fontFamily } = useStyle();
  const { t } = useTranslation();

  return (
    <Stack
      w={window.innerWidth}
      h={window.innerHeight}
      overflow="auto"
      bg={cBg}
    >
      <CartItems />
      <Stack
        pos="fixed"
        bottom="10"
        right={window.innerWidth * 0.01}
        left={window.innerWidth * 0.01}
      >
        <Flex justifyContent="center">
          <Button
            alignSelf="center"
            p="6"
            onClick={() => history.goBack()}
            w={window.innerWidth * 0.3}
            h={window.innerHeight * 0.11}
            borderWidth="2px"
            bg="#F2F2F2"
            shadow="lg"
            borderRadius="lg"
          >
            <Text
              fontSize="md"
              fontWeight="bold"
              textShadow="text"
              textColor="white"
              fontFamily={fontFamily}
            >
              {t('back')}
            </Text>
          </Button>
          <Box w={window.innerWidth * 0.007} />
          <SendButton />
        </Flex>
      </Stack>
    </Stack>
  );
};
