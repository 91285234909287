import { ThemeOverride } from '@chakra-ui/react';

export const colors: ThemeOverride['colors'] = {
  focusBorderColor: 'white',
  gray: {
    100: '#eeeeee',
    300: '#F3F2F3',
  },
  green: {
    200: '#08B53B',
  },
  brand: {
    500: '#DE2A28',
  },
};
