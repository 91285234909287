import { db } from '../firebase';
import firebase from 'firebase/app';
import { updateDocument } from './common';
import {
  TransactionFromJson,
  Collection,
  NotAgreedAlcholCustomer,
} from 'smartdishpro-scripts/ts_common';
import { TransHistory } from '../hooks/useHistory/useHistory';
import { getOrders } from './order';

export const transRef = (merchantId: string, storeId: string) =>
  db
    .collection(Collection.Merchants)
    .doc(merchantId)
    .collection(Collection.Stores)
    .doc(storeId)
    .collection(Collection.Transactions);

export const updateCustomerIds = async (
  merchantId: string,
  storeId: string,
  transId: string,
  customerId: string
) => {
  await transRef(merchantId, storeId)
    .doc(transId)
    .update(
      updateDocument({
        customerIds: firebase.firestore.FieldValue.arrayUnion(customerId),
      })
    );
};

export const updateTapPayButton = async (
  merchantId: string,
  storeId: string,
  transId: string
) => {
  await transRef(merchantId, storeId)
    .doc(transId)
    .update(
      updateDocument({
        lastTappedPayButtonAt: firebase.firestore.FieldValue.serverTimestamp(),
        readLastTappedStoreDeviceIds: [],
      })
    );
};

export const updateTapNotAgreeButton = async (
  merchantId: string,
  storeId: string,
  transId: string,
  customers: NotAgreedAlcholCustomer[]
) => {
  await transRef(merchantId, storeId)
    .doc(transId)
    .update(
      updateDocument({
        notAgreedAlcholCustomers: customers,
        readLastTappedStoreDeviceIds: [],
      })
    );
};

export const getTransactions = async (
  merchantId: string,
  storeId: string,
  customerId: string
): Promise<TransHistory[]> => {
  const snapshot = await transRef(merchantId, storeId)
    .where('documentStatus', '==', 'active')
    .where('customerIds', 'array-contains', customerId)
    .where('status', 'in', ['underTransaction', 'completeTransaction'])
    .orderBy('createdAt', 'desc')
    .limit(10)
    .get();

  const results: TransHistory[] = [];

  for (let index = 0; index < snapshot.docs.length; index++) {
    const doc = snapshot.docs[index];
    results.push({
      trans: TransactionFromJson(
        doc.data({ serverTimestamps: 'estimate' }),
        doc.id
      ),
      orders: [],
    });
  }

  return results;
};

export const getTransactionOrders = async (
  merchantId: string,
  storeId: string,
  histories: TransHistory[]
): Promise<TransHistory[]> => {
  const results: TransHistory[] = [];

  for (let index = 0; index < histories.length; index++) {
    const doc = histories[index].trans;
    const orders = await getOrders(merchantId, storeId, doc.id ?? '');
    results.push({
      trans: doc,
      orders: orders,
    });
  }

  return results;
};
