import { createContext, useContext, useReducer, VFC, ReactNode } from 'react';
import { Cart, CartAction, cartReducer } from './cartReducer';

export type CartState = Cart & {
  dispatch: (action: CartAction) => void;
};

const CartContext = createContext<CartState>({
  items: [],
  trans: undefined,
  orders: [],
  customers: [],
  dispatch: () => {
    throw new Error('Unimplemented!');
  },
});

export const useCart = (): CartState => useContext(CartContext);

type Props = {
  children: ReactNode;
};

export const CartProvider: VFC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, {
    items: [],
    trans: undefined,
    orders: [],
    customers: [],
  });

  return (
    <CartContext.Provider value={{ ...state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};
