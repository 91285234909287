import { Text, Divider, Box } from '@chakra-ui/react';
import { VFC } from 'react';
import { Item, OptionItem } from 'smartdishpro-scripts/ts_common';
import useWindowSize from '../../../hooks/useDevice';
import { useStyle } from '../../../hooks/useStyle';
import { ItemCard } from './itemCard';

type Props = {
  items: (Item & { defaultOptionItems: OptionItem[] })[];
  categoryName: string;
};

export const ItemList: VFC<Props> = ({ items, categoryName }) => {
  const { width, itemHeight } = useWindowSize();
  const { fontFamily } = useStyle();

  return (
    <Box
      w={width * 0.8}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {/*  リストの最初にカテゴリー名を付ける */}
      <Box
        w={width * 0.75}
        h={`${itemHeight}px`}
        display="flex"
        flexDirection="column"
        justifyContent="space-evenly"
      >
        <Text
          isTruncated
          paddingLeft={2}
          fontSize="md"
          fontWeight="bold"
          textShadow="text"
          textColor="white"
          textOverflow="ellipsis"
          fontFamily={fontFamily}
        >
          {categoryName}
        </Text>
        <Divider />
      </Box>
      {items?.map((item, i) => {
        return <ItemCard key={`${i}${item.id}`} item={item} />;
      })}
    </Box>
  );
};
