import firebase from 'firebase/app';

export const DocumentStatus = { active: 'active', deleted: 'deleted' };

export const createDocument = (document: any) => {
  const { id, ...newDoc } = document;
  return {
    ...newDoc,
    documentStatus: DocumentStatus.active,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    deletedAt: null,
  };
};

export const updateDocument = (document: any) => {
  const { id, ...newDoc } = document;
  return {
    ...newDoc,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
};

export const deleteDocument = () => {
  return {
    documentStatus: DocumentStatus.deleted,
    deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
};
