import { useAsync } from '../useAsync';
import { useCustomer } from '../useCustomer';
import { useCart } from '../useCart';
import { updateTapNotAgreeButton } from '../../repositories/transaction';

export const useAgreeAlcholTerms = (): {
  onTapNotAgree: () => void;
} => {
  const { merchantId, store, id } = useCustomer();
  const { trans } = useCart();
  const { execute: onTapPayment } = useAsync(async () => {
    try {
      if (!merchantId || !store?.id || !trans?.id) {
        return;
      }

      await updateTapNotAgreeButton(merchantId, store.id!, trans.id!, [
        ...trans.notAgreedAlcholCustomers,
        {
          customerId: id ?? '',
          tappedAt: new Date(),
        },
      ]);
    } catch (error) {}
  });

  return {
    onTapNotAgree: onTapPayment,
  };
};
