import { Item, Order, Transaction } from 'smartdishpro-scripts/ts_common';

export type TransHistory = {
  trans: Transaction;
  orders: Order[];
};

export type ItemHistory = {
  itemId: string;
  item: Item;
  quantity: number;
};

export const useHistory = (trans?: TransHistory[], items?: Item[]) => {
  const _items = trans
    ?.map((_t) =>
      _t.orders
        .map((_o) => {
          const _i = _o.orderItem;
          const _item = items?.find((_item) => _item.id === _i.itemId);
          if (!_item) {
            return [];
          }
          return [
            {
              itemId: _i.itemId,
              item: _item,
              quantity: _i.quantity,
            },
          ];
        })
        .flat()
    )
    .flat();

  return _items
    ?.map((_i) => {
      let quantity = 0;
      const _oItems = _items.filter((_item) => _item.itemId === _i.itemId);
      _oItems.forEach((_item) => {
        quantity += _item.quantity;
      });
      return { ..._i, quantity: quantity };
    })
    .filter((x, i, arr) => arr.findIndex((y) => y.itemId === x.itemId) === i)
    .sort((a, b) => b.quantity - a.quantity);
};
