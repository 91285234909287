export const dateToStringYMD = (value: Date | null): string =>
  value === null
    ? ''
    : `${value.getFullYear()}/${value.getMonth() + 1}/${value.getDate()}`;

export const dateToMD = (value: Date | null): string =>
  value === null ? '' : `${value.getMonth() + 1}/${value.getDate()}`;

export const dateFromNow = (days: number | null): Date | null => {
  if (days === null) {
    return null;
  }

  const _now = new Date();
  const _value = new Date(
    _now.getFullYear(),
    _now.getMonth() + 1,
    _now.getDate()
  );
  _value.setDate(_value.getDate() + days);
  return _value;
};

export const dateToYMD = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
};

export const minutesTo = (date: Date): number => {
  const now = new Date();
  return Math.floor((date.getTime() - now.getTime()) / (1000 * 60));
};
