import { db } from '../firebase';
import { Item, ItemFromJson, Collection } from 'smartdishpro-scripts/ts_common';
import { DocumentStatus } from './common';

const _itemsCollection = (merchantId: string, storeId: string) =>
  db
    .collection(Collection.Merchants)
    .doc(merchantId)
    .collection(Collection.Stores)
    .doc(storeId)
    .collection(Collection.Items);

export const getItems = async (
  merchantId: string,
  storeId: string,
  currentHourId: string
): Promise<Item[]> => {
  const snapshot = await _itemsCollection(merchantId, storeId)
    .where('documentStatus', '==', DocumentStatus.active)
    .where('isPublic', '==', true)
    .where('storeHourIds', 'array-contains', currentHourId)
    .limit(350)
    .get();

  return snapshot.docs.map((doc) =>
    ItemFromJson(doc.data({ serverTimestamps: 'estimate' }), doc.id)
  );
};

export const getItem = async (
  merchantId: string,
  storeId: string,
  itemId: string
): Promise<Item | null> => {
  const snapshot = await _itemsCollection(merchantId, storeId)
    .doc(itemId)
    .get();

  return !snapshot.exists
    ? null
    : ItemFromJson(
        snapshot.data({ serverTimestamps: 'estimate' })!,
        snapshot.id
      );
};
