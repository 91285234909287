import {
  Customer,
  Order,
  OrderItem,
  Payment,
  Transaction,
} from 'smartdishpro-scripts/ts_common';
import { existSameOrderItems } from '../../utils/checkItems';

export enum CartActionType {
  SET_TRANS = 'SET_TRANS',
  SET_ORDERS = 'SET_ORDERS',
  SET_PAYMENTS = 'SET_PAYMENTS',
  SET_CUSTOMERS = 'SET_CUSTOMERS',
  ADD_ITEM = 'ADD_ITEM',
  REMOVE_ITEM = 'REMOVE_ITEM',
  PLUS_ITEM = 'PLUS_ITEM',
  MINUS_ITEM = 'MINUS_ITEM',
  CLEAR = 'CLEAR',
}

export type Cart = {
  items: OrderItem[];
  trans?: Transaction;
  orders: Order[];
  customers: Customer[];
};

export type CartAction =
  | {
      type: CartActionType.SET_TRANS;
      payload: Transaction;
    }
  | {
      type: CartActionType.SET_ORDERS;
      payload: Order[];
    }
  | {
      type: CartActionType.SET_PAYMENTS;
      payload: Payment[];
    }
  | {
      type: CartActionType.SET_CUSTOMERS;
      payload: Customer[];
    }
  | {
      type: CartActionType.ADD_ITEM;
      payload: OrderItem;
    }
  | {
      type: CartActionType.REMOVE_ITEM;
      payload: OrderItem;
    }
  | {
      type: CartActionType.PLUS_ITEM;
      payload: OrderItem;
    }
  | {
      type: CartActionType.MINUS_ITEM;
      payload: OrderItem;
    }
  | {
      type: CartActionType.CLEAR;
      payloadTrans: Transaction;
      payloadOrders: Order[];
    };

export const cartReducer = (state: Cart, action: CartAction): Cart => {
  switch (action.type) {
    case CartActionType.SET_TRANS:
      return {
        ...state,
        trans: action.payload,
      };

    case CartActionType.SET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };

    case CartActionType.SET_PAYMENTS:
      return {
        ...state,
        trans: { ...state.trans!, payments: action.payload },
      };

    case CartActionType.SET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };

    case CartActionType.ADD_ITEM:
      return {
        ...state,
        items: existSameOrderItems(state.items, action.payload)
          ? state.items.map((item) =>
              item.itemId === action.payload.itemId
                ? { ...item, quantity: item.quantity + action.payload.quantity }
                : item
            )
          : state.items.concat(action.payload),
      };

    case CartActionType.REMOVE_ITEM:
      return {
        ...state,
        items: state.items.filter((obj) => obj !== action.payload),
      };

    case CartActionType.PLUS_ITEM:
      const plusedItems = state.items.map((item) => {
        return item === action.payload && item.quantity < 20
          ? { ...item, quantity: item.quantity + 1 }
          : item;
      });
      return {
        ...state,
        items: plusedItems,
      };

    case CartActionType.MINUS_ITEM:
      const minusedItems = state.items.map((item) => {
        return item === action.payload && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item;
      });
      return {
        ...state,
        items: minusedItems,
      };

    case CartActionType.CLEAR:
      return {
        ...state,
        items: [],
        trans: action.payloadTrans,
        orders: action.payloadOrders,
      };

    default:
      return state;
  }
};
