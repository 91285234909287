import { OrderItem } from 'smartdishpro-scripts/ts_common';

export const existSameOrderItems = (
  existItems: OrderItem[],
  newItem: OrderItem
): boolean => {
  let sameOrderItem = [];
  existItems.forEach((item) => {
    if (item.itemId === newItem.itemId) {
      let sameOptionItems = false;
      item.optionItems.forEach((item) => {
        if (!newItem.optionItems.includes(item)) {
          sameOptionItems = false;
          return;
        } else {
          sameOptionItems = true;
        }
      });
      if (sameOptionItems) {
        sameOrderItem.push(item);
      }
    }
  });
  return sameOrderItem.length > 0;
};
