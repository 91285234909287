import { Collection, SurveyResult } from 'smartdishpro-scripts/ts_common';
import { db } from '../firebase';
import { createDocument } from './common';

const _surveyResultRef = (merchantId: string) =>
  db
    .collection(Collection.Merchants)
    .doc(merchantId)
    .collection(Collection.SurveyResults);

export const createSurveyResult = async (
  merchantId: string,
  doc: SurveyResult
) => {
  await _surveyResultRef(merchantId).add(createDocument(doc));
};
