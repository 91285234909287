import { ComponentStyleConfig } from "@chakra-ui/react";

export const FormError: ComponentStyleConfig = {
  baseStyle: {
    text: {
      color: "red.500",
      fontSize: "xs",
      fontWeight: "bold",
    },
  },
};
