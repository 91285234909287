import { Flex, Text, Image, Button, Box } from '@chakra-ui/react';
import { useCallback, VFC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { useCustomer } from '../../../hooks/useCustomer';
import { getMCusPCard } from '../../../repositories/mCusPCard';
import { getPointCard } from '../../../repositories/pointCard';
import { getStore } from '../../../repositories/stores';
import { route } from '../../../router';
import '@fontsource/noto-sans-jp/900.css';
import { isAndroid } from '../../../utils/device';

export const PointCardPage: VFC = () => {
  const history = useHistory();

  const { merchantId, store, mCusPCards } = useCustomer();

  const _pointCardId = useLocation().pathname.split('/')[4];

  const _mCusPCard =
    mCusPCards.filter((_card) => _card.pCardId === _pointCardId).length < 1
      ? null
      : mCusPCards.filter((_card) => _card.pCardId === _pointCardId)[0];

  const { data: mCusPCard } = useSWR(
    [`/mCusPCards`, merchantId, _mCusPCard?.mCusPCardId],
    () => getMCusPCard(merchantId ?? '', _mCusPCard?.mCusPCardId ?? '')
  );

  const { data: mCusPCardCreatedStore } = useSWR(
    [`/mCusPCardCreatedStore`, merchantId, mCusPCard?.createdStoreId],
    () => getStore(merchantId ?? '', mCusPCard?.createdStoreId ?? '')
  );

  const onClick = useCallback(() => {
    history.push(`${route.root}${merchantId}/${store?.id}${route.pointCards}`);
  }, [history, merchantId, store?.id]);

  const { data: pointCard } = useSWR(
    [`/pointCard`, merchantId, _pointCardId],
    () => getPointCard(merchantId ?? '', _pointCardId)
  );

  return (
    <Flex
      direction="column"
      backgroundColor="gray.100"
      padding={5}
      minH={isAndroid ? `${window.innerHeight}px` : window.innerHeight}
    >
      <Box
        paddingX={5}
        paddingY={7}
        borderRadius="lg"
        borderWidth="1px"
        borderColor="lightgray"
        shadow="lg"
        backgroundColor="white"
      >
        <Text
          textAlign="center"
          color="black"
          fontSize="lg"
          fontWeight={1000}
          fontFamily="notoSansJp"
        >
          {mCusPCard?.pointCardTitle ?? ''}
        </Text>
        <Box paddingY={3}>
          <Image
            src={pointCard?.imageUrl ?? undefined}
            objectFit="cover"
            alignSelf="center"
          />
        </Box>
        <Flex paddingTop={1} flexDirection="row">
          <Flex
            w="70%"
            justify="center"
            alignItems="start"
            padding={3}
            flexDirection="column"
            borderWidth="1px"
            borderColor="lightgray"
          >
            <Text
              maxW="100%"
              textAlign="start"
              color="black"
              fontSize="lg"
              fontWeight={1000}
              fontFamily="notoSansJp"
              style={{
                maxLines: 1,
                overflow: 'hidden',
              }}
            >
              {mCusPCard?.num}
            </Text>
            <Text
              maxW="100%"
              textAlign="start"
              color="black"
              fontSize="xl"
              fontWeight={1000}
              fontFamily="notoSansJp"
              style={{
                maxLines: 1,
                overflow: 'hidden',
              }}
            >
              {mCusPCard?.name}
            </Text>
          </Flex>
          <Flex
            w="30%"
            justify="center"
            alignItems="center"
            flexDirection="column"
            borderWidth="1px"
            borderColor="lightgray"
            padding={1}
          >
            <Text
              paddingTop={1}
              maxW="100%"
              style={{
                maxLines: 1,
                overflow: 'hidden',
              }}
              color="black"
              fontSize="xx-large"
              fontWeight={1000}
              fontFamily="notoSansJp"
            >
              {mCusPCard?.point ?? '-'}
            </Text>
            <Text
              paddingBottom={2}
              textAlign="center"
              color="black"
              fontSize="sm"
              fontWeight={1000}
              fontFamily="notoSansJp"
            >
              ポイント
            </Text>
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          padding={3}
          flexDirection="column"
          borderWidth="1px"
          borderColor="lightgray"
        >
          <Text
            textAlign="center"
            color="black"
            fontSize="sm"
            alignSelf="start"
            fontWeight="medium"
            fontFamily="notoSansJp"
          >
            【登録店舗】
          </Text>
          <Text
            textAlign="center"
            color="black"
            fontSize="sm"
            paddingY={2}
            fontWeight="medium"
            fontFamily="notoSansJp"
          >
            {mCusPCardCreatedStore?.storeName ?? ''}
          </Text>
        </Flex>
      </Box>
      <Box marginTop={8} padding={8} bgColor="blackAlpha.200">
        <Text
          textAlign="start"
          fontSize="sm"
          whiteSpace="pre-line"
          fontWeight="medium"
          fontFamily="notoSansJp"
        >
          {pointCard?.caution ?? ''}
        </Text>
      </Box>
      <Flex paddingY={10} justify="center">
        <Flex borderWidth="1px" borderColor="blackAlpha.700" borderRadius="md">
          <Button
            size="md"
            textColor="blackAlpha.800"
            bgColor="white"
            onClick={onClick}
            fontWeight="medium"
            fontFamily="notoSansJp"
          >
            ポイントカード一覧へ
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
