import liff from '@line/liff';
import { useState } from 'react';
import { CustomerActionType, useCustomer } from '../useCustomer';
import {
  createCustomer,
  getCustomer,
  getCustomerDoc,
  updateCustomer,
} from '../../repositories/customers';
import { useAsync } from '../useAsync';
import {
  getTransactions,
  transRef,
  updateCustomerIds,
} from '../../repositories/transaction';
import { CartActionType, useCart } from '../useCart';
import { getOrders } from '../../repositories/order';
import { getMCusPCards } from '../../repositories/mCusPCard';
import { checkStoreOpen } from './checkStoreOpen';
import {
  Customer,
  StampCardHistory,
  Transaction,
  TransactionFromJson,
} from 'smartdishpro-scripts/ts_common';
import { getPayments } from '../../repositories/payment';
import { dateFromNow, dateToYMD } from '../../utils/date';
import { TransHistory } from '../useHistory/useHistory';
import { useHistory } from 'react-router-dom';
import { route } from '../../router';
import i18next from 'i18next';
import { functions } from '../../firebase';

// 1. debug or release　モード
// 2. URL から merchantId、storeId、transactionId を取得
// 3. ログインアカウントの取得（liff）
// 4. 店舗情報の取得
// 5. ユーザ情報の取得
// 6. ポイントカードの閲覧URLの場合は、ポイントカードのページに遷移
// 7. 取引情報の取得(listen)

export const useInit = (): {
  init: () => void;
  isLoading: boolean;
  error?: string;
} => {
  /// 言語の切替をする前の読み込みのため、日本語のみ
  const [error, setError] = useState<string | undefined>();

  const { dispatch: customerDispatch } = useCustomer();
  const { dispatch: cartDispatch } = useCart();
  const _useHistory = useHistory();

  const { isLoading, execute: init } = useAsync(async () => {
    let merchantId: string | undefined;
    let storeId: string | undefined;
    let thirdPath: string | undefined;
    let fourthPath: string | undefined;
    let lineId: string | undefined;
    let displayName: string | undefined;
    let pictureUrl: string | undefined;
    setError(undefined);

    // 1. debug or release　モード

    const isDebug = process.env.REACT_APP_MODE === 'debug';

    // 2. URL から merchantId、storeId、transactionId を取得
    if (isDebug) {
      merchantId = '0cnNNajvs6zKeJyOT2Gb';
      storeId = 'SpbFkQUUY9V6UU729Iv2';
      thirdPath = 'fe6aacbf-f8c0-4753-a4b0-b562338caa62';
      // thirdPath = 'point_cards';
      // fourthPath = 'Mo2Xz2Grgb80AUpmMZip';
      // thirdPath = 'survey';
      // fourthPath = 'KOBzKcpxwsG2zwKsPYeA';
      lineId = 'U024ee4168992114bb7c990985fcd7926';
    } else {
      merchantId = window.location.pathname.split('/')[1];
      storeId = window.location.pathname.split('/')[2];
      thirdPath = window.location.pathname.split('/')[3];
      fourthPath = window.location.pathname.split('/')[4];
    }

    const result = await checkStoreOpen(merchantId, storeId);

    // 3. ログインアカウントの取得（liff）
    if (!isDebug) {
      if (!result.store?.liffId) {
        setError(
          '正しく読み込めませんでした もう一度カメラでQRをお読みください'
        );
        return;
      }

      await liff.init({
        liffId: result.store.liffId,
      });

      const isLineBrowser = liff.isInClient();
      if (!isLineBrowser) {
        setError('LINE内でのみご利用できます');
        return;
      }

      const profile = await liff.getProfile();
      lineId = profile.userId;
      displayName = profile.displayName;
      pictureUrl = profile.pictureUrl;
    }

    customerDispatch({
      type: CustomerActionType.SET_MERCHNTID,
      payload: merchantId,
    });

    // 4. 店舗情報の取得(営業時間内かどうか)
    if (result?.store === null || result?.store === undefined) {
      setError(
        '店舗の情報を確認できませんでした もう一度カメラでQRをお読みください'
      );
      return;
    } else {
      customerDispatch({
        type: CustomerActionType.SET_STORE,
        payload: result!.store,
      });
    }

    if (result!.store!.isOpen && result.storeHourId) {
      customerDispatch({
        type: CustomerActionType.SET_STOREHOUR_ID,
        payload: result.storeHourId,
      });
    } else {
      setError('こちらの店舗は営業時間外です');
      return;
    }

    // 5. ユーザ情報の取得（Firestore）
    if (!lineId) {
      setError(
        'お客様の情報を確認できませんでした もう一度カメラでQRをお読みください'
      );
      return;
    }

    let customer =
      (await getCustomer(merchantId, storeId, lineId)) ??
      (await createCustomer(
        merchantId,
        storeId,
        lineId,
        displayName ?? '',
        pictureUrl,
        dateFromNow(result!.store.stampExpiredDays),
        result!.store.stampExpiredDays === null ? null : dateToYMD(new Date())
      ));

    if (!customer) {
      setError(
        'お客様の情報を確認できませんでした もう一度カメラでQRをお読みください'
      );
      return;
    }

    let _transHistories: TransHistory[] = [];
    try {
      _transHistories = await getTransactions(
        merchantId,
        storeId,
        customer.id!
      );
    } catch (error) {}

    customerDispatch({
      type: CustomerActionType.SET_TRANS_HISTORIES,
      payload: _transHistories,
    });

    if (customer.pictureUrl !== pictureUrl) {
      customer = {
        ...customer,
        pictureUrl: pictureUrl ?? null,
      };
    }

    if (customer.name !== displayName) {
      customer = {
        ...customer,
        name: displayName ?? '',
      };
    }

    let history: StampCardHistory | undefined;

    if (
      result!.store.stampExpiredDays !== null &&
      customer.stampExpiredAt === null
    ) {
      // お客様の有効期限の設定がされていない
      customer = {
        ...customer,
        stampExpiredAt: dateFromNow(result!.store.stampExpiredDays),
        stampStartedAt: dateToYMD(new Date()),
      };
    } else if (
      customer.stampStartedAt !== null &&
      customer.stampExpiredAt !== null &&
      customer.stampExpiredAt.getTime() < new Date().getTime()
    ) {
      // お客様の有効期限が切れている
      history = {
        createdAt: new Date(),
        deletedAt: null,
        documentStatus: 'active',
        numOfStamps: _transHistories.filter(
          (_v) =>
            _v.trans.status === 'completeTransaction' ||
            _v.trans.status === 'cancelledTransBeforeComplete' ||
            _v.trans.status === 'cancelledTransAfterComplete'
        ).length,
        stampExpiredAt: customer.stampExpiredAt,
        stampStartedAt: customer.stampStartedAt,
        updatedAt: new Date(),
      };

      customer = {
        ...customer,
        stampExpiredAt: dateFromNow(result!.store.stampExpiredDays),
        stampStartedAt:
          result!.store.stampExpiredDays === null
            ? null
            : dateToYMD(new Date()),
      };
    }

    try {
      await updateCustomer(
        merchantId,
        storeId,
        customer.id!,
        customer.name ?? '',
        customer.pictureUrl ?? null,
        customer.stampExpiredAt ?? null,
        customer.stampStartedAt ?? null,
        history
      );
    } catch (error) {}

    customerDispatch({
      type: CustomerActionType.UPDATE_CUSTOMER,
      payload: customer,
    });

    if (!thirdPath) {
      setError('お客さま専用QRをカメラからお読みください');
      return;
    }

    if (thirdPath === 'point_cards') {
      if (!fourthPath || !customer.mCustomerId) {
        setError('ポイントカード情報を取得する際にエラーが発生しました');
        return;
      }

      const _mCusPCards = await getMCusPCards(merchantId, customer.mCustomerId);
      if (
        !_mCusPCards.some((_card) => _card.value.pointCardId === fourthPath)
      ) {
        _useHistory.push(
          `${route.root}${merchantId}/${storeId}${route.pointCards}/${fourthPath}/create`
        );
      } else {
        _useHistory.push(
          `${route.root}${merchantId}/${storeId}${route.pointCards}/${fourthPath}`
        );
      }

      customerDispatch({
        type: CustomerActionType.SET_MCUSPCARDS,
        payload: _mCusPCards,
      });
      return;
    }

    if (thirdPath === 'survey') {
      if (!fourthPath) {
        setError('アンケート情報を取得する際にエラーが発生しました');
        return;
      }

      _useHistory.push(
        `${route.root}${merchantId}/${storeId}${route.survey}/${fourthPath}`
      );

      return;
    }

    const transId = thirdPath;

    // 6. 取引情報の取得
    transRef(merchantId, storeId)
      .doc(transId)
      .onSnapshot(async (snap) => {
        let trans: Transaction | undefined;
        setError(undefined);

        const data = snap.data({ serverTimestamps: 'estimate' });
        if (snap.exists && data) {
          trans = TransactionFromJson(data, snap.id);
        }

        if (trans) {
          cartDispatch({
            type: CartActionType.SET_TRANS,
            payload: trans,
          });

          const payments = await getPayments(merchantId!, storeId!, trans.id!);
          cartDispatch({
            type: CartActionType.SET_PAYMENTS,
            payload: payments,
          });
        } else {
          setError(
            'テーブルの情報を確認できませんでした もう一度カメラでQRをお読みください'
          );
          return;
        }

        if (trans?.status !== 'underTransaction') {
          setError(
            'ご来店ありがとうございました またのご来店を、心よりお待ちしております'
          );
          return;
        }

        const orders = await getOrders(merchantId!, storeId!, transId);
        cartDispatch({
          type: CartActionType.SET_ORDERS,
          payload: orders,
        });

        let customers: Customer[] = [customer!];
        const ids = trans?.customerIds.filter((_id) => customer?.id !== _id);
        for (let index = 0; index < ids.length; index++) {
          try {
            const _customer = await getCustomerDoc(
              merchantId!,
              storeId!,
              ids[index]
            );
            if (_customer) {
              customers.push(_customer);
            }
          } catch (e) {}
        }

        cartDispatch({
          type: CartActionType.SET_CUSTOMERS,
          payload: customers,
        });

        if (!trans?.customerIds.includes(customer!.id!)) {
          if (
            !_transHistories.some((_history) => _history.trans.id === trans?.id)
          ) {
            customerDispatch({
              type: CustomerActionType.SET_TRANS_HISTORIES,
              payload: _transHistories.concat({ trans: trans, orders: [] }),
            });
          }

          if (result!.store?.stampFunctionInUse === true) {
            // QRの初回読み込み時に、スタンプカードを表示させるため
            customerDispatch({
              type: CustomerActionType.SET_CHECKEDIN,
              payload: false,
            });
          }

          if (merchantId === 'SpbFkQUUY9V6UU729Ivk') {
            // QRの初回読み込み時に、アルコールの確認
            await i18next.changeLanguage('ja');
            customerDispatch({
              type: CustomerActionType.SET_CHECKEDIN_ALCHOL,
              payload: false,
            });
          }

          await updateCustomerIds(
            merchantId!,
            storeId!,
            transId,
            customer!.id!
          );

          if (!isDebug) {
            const userAToken = liff.getAccessToken();
            await functions.httpsCallable('LineSendServiceMessage').call(
              {},
              {
                merchantId: merchantId,
                storeId: storeId,
                tableOrderUrl: trans.tableOrderUrl,
                userAToken: userAToken,
              }
            );
          }
        }
      });

    _useHistory.push(
      `${route.root}${merchantId}/${storeId}/${transId}${route.items}`
    );
  });

  return { init, isLoading, error };
};
