import { useAsync } from '../useAsync';
import { useCustomer } from '../useCustomer';
import { useCart } from '../useCart';
import { updateTapPayButton } from '../../repositories/transaction';

export const usePayment = (): {
  onTapPayment: () => void;
} => {
  const { merchantId, store } = useCustomer();
  const { trans } = useCart();
  const { execute: onTapPayment } = useAsync(async () => {
    try {
      if (!merchantId || !store?.id || !trans?.id) {
        return;
      }

      await updateTapPayButton(merchantId, store.id!, trans.id!);
    } catch (error) {}
  });

  return {
    onTapPayment,
  };
};
