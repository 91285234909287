import { ComponentStyleConfig } from "@chakra-ui/react";

export const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    alignItems: "center",
    color: "gray.900",
    display: "flex",
    fontSize: "md",
    fontWeight: "bold",
    margin: "0",
  },
};
