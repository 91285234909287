import { useForm } from 'react-hook-form';
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  Image,
  Text,
  useToast,
  Stack,
  Box,
} from '@chakra-ui/react';
import { VFC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { CustomerActionType, useCustomer } from '../../../hooks/useCustomer';
import { createMCusPCard } from '../../../repositories/mCusPCard';
import { getPointCard } from '../../../repositories/pointCard';
import { route } from '../../../router';
import { useStyle } from '../../../hooks/useStyle';
import '@fontsource/noto-sans-jp/900.css';
import { isAndroid } from '../../../utils/device';

export const PointCardCreatePage: VFC = () => {
  const toast = useToast();

  const history = useHistory();

  const { merchantId, store, mCustomerId } = useCustomer();

  const _pointCardId = useLocation().pathname.split('/')[4];

  const { data: pointCard } = useSWR(
    [`/pointCard`, merchantId, _pointCardId],
    () => getPointCard(merchantId ?? '', _pointCardId)
  );

  const { dispatch: customerDispatch } = useCustomer();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  function onSubmit(value: any) {
    return new Promise(async (resolve) => {
      setTimeout(async () => {
        if (!!!store?.id || !!!pointCard || !!!mCustomerId) {
          toast({
            title: `エラーが発生しました。再度ページを開き直してください。`,
            status: 'error',
            position: 'top',
            isClosable: true,
          });
          return;
        }

        let mCusCardId = '';
        try {
          mCusCardId = await createMCusPCard(merchantId ?? '', {
            availableStoreIds: pointCard.availableStoreIds,
            createdAt: new Date(),
            createdStoreId: store.id,
            deletedAt: null,
            documentStatus: 'active',
            expireAt: pointCard.expireAt,
            histories: [],
            mCustomerId: mCustomerId,
            name: value.name as string,
            num: parseInt(value.num),
            point: 0,
            pointCardId: _pointCardId,
            pointCardTitle: pointCard.title,
            updatedAt: new Date(),
          });

          toast({
            title: `登録が完了しました`,
            status: 'success',
            position: 'top',
            isClosable: true,
          });

          customerDispatch({
            type: CustomerActionType.ADD_MCUSPCARD,
            payload: { mCusPCardId: mCusCardId, pCardId: _pointCardId },
          });

          history.push(
            `${route.root}${merchantId}/${store?.id}${route.pointCards}`
          );
        } catch (error) {
          console.log(error);
        }
        resolve(null);
      }, 3000);
    });
  }

  const { fontFamily } = useStyle();

  if (pointCard && pointCard.expireAt.getTime() < new Date().getTime()) {
    return (
      <Stack w={window.innerWidth} h={window.innerHeight} bg="gray.300">
        <Flex
          justify="center"
          flexDirection="column"
          height="100vh"
          justifyContent="space-evenly"
        >
          <Text
            textAlign="center"
            color="grey"
            fontSize="xx-small"
            fontFamily={fontFamily}
          >
            このポイントカードは有効期限が切れているため、発行できません
          </Text>
        </Flex>
      </Stack>
    );
  }

  if (pointCard && !pointCard.availableStoreIds.includes(store?.id ?? '')) {
    return (
      <Stack w={window.innerWidth} h={window.innerHeight} bg="gray.300">
        <Flex
          justify="center"
          flexDirection="column"
          height="100vh"
          justifyContent="space-evenly"
        >
          <Text
            textAlign="center"
            color="grey"
            fontSize="xx-small"
            fontFamily={fontFamily}
          >
            このポイントカードはご利用いただけません
          </Text>
        </Flex>
      </Stack>
    );
  }

  return (
    <Flex
      direction="column"
      backgroundColor="gray.100"
      padding={5}
      minH={isAndroid ? `${window.innerHeight}px` : window.innerHeight}
    >
      <Box
        paddingX={5}
        paddingY={7}
        borderRadius="lg"
        borderWidth="1px"
        borderColor="lightgray"
        shadow="lg"
        backgroundColor="white"
      >
        <Text
          textAlign="center"
          color="black"
          fontSize="lg"
          fontWeight={1000}
          fontFamily="notoSansJp"
        >
          {pointCard?.title ?? ''}
        </Text>
        <Box paddingY={3}>
          <Image
            src={pointCard?.imageUrl ?? undefined}
            objectFit="cover"
            alignSelf="center"
          />
        </Box>
        <Box height="30px" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.name !== undefined}>
            <FormLabel htmlFor="name" paddingBottom={2}>
              ニックネーム
            </FormLabel>
            <Input
              id="name"
              placeholder="ニックネーム"
              {...register('name', {
                required: 'この項目は必須です',
                minLength: { value: 1, message: '1文字以上' },
              })}
              _placeholder={{ color: 'lightgray' }}
              borderColor="lightgray"
              focusBorderColor="lightgray"
            />
            <FormErrorMessage>
              {errors.name && errors.name.message}
            </FormErrorMessage>
            <FormLabel htmlFor="num" marginTop={5} paddingBottom={2}>
              番号
            </FormLabel>
            <Input
              id="num"
              placeholder="お好きな番号を入力してください"
              type="number"
              minLength={1}
              {...register('num', {
                required: 'この項目は必須です',
                minLength: { value: 1, message: '1桁以上' },
                maxLength: { value: 10, message: '10桁以下' },
                min: { value: 0, message: '0以上' },
                max: { value: 9999999999, message: '9999999999以下' },
              })}
              _placeholder={{ color: 'lightgray' }}
              borderColor="lightgray"
              focusBorderColor="lightgray"
            />
            <FormErrorMessage>
              {errors.num && errors.num.message}
            </FormErrorMessage>
            <Flex direction="column" alignItems="center">
              <Button
                flexDirection="column"
                marginTop={10}
                paddingX={8}
                textColor="white"
                bgColor="#DE2A28"
                fontWeight="medium"
                isLoading={isSubmitting}
                type="submit"
                borderRadius={20}
              >
                登録する
              </Button>
            </Flex>
          </FormControl>
        </form>
      </Box>
      <Box marginTop={8} padding={8} bgColor="blackAlpha.200">
        <Text
          textAlign="start"
          fontSize="sm"
          whiteSpace="pre-line"
          fontWeight="medium"
          fontFamily="notoSansJp"
        >
          {pointCard?.caution ?? ''}
        </Text>
      </Box>
    </Flex>
  );
};
