import { Form } from "./form";
import { FormError } from "./formError";
import { FormLabel } from "./formLabel";
import { Input } from "./input";
import { Textarea } from "./textarea";
import { Select } from "./select";
import { Button } from "./button";

export const components = {
  Form,
  FormError,
  FormLabel,
  Input,
  Textarea,
  Select,
  Button,
};
