import {
  OrderItem,
  Order,
  OptionItem,
  Item,
} from 'smartdishpro-scripts/ts_common';

export const convertOrderItems = (
  orderItems: OrderItem[]
): {
  itemId: string;
  quantity: number;
}[] => {
  let _values: {
    itemId: string;
    quantity: number;
  }[] = [];
  for (let index = 0; index < orderItems.length; index++) {
    const orderItem = orderItems[index];
    const _i = _values.findIndex((_v) => _v.itemId === orderItem.itemId);
    if (_i >= 0) {
      _values[_i] = {
        itemId: _values[_i].itemId,
        quantity: _values[_i].quantity + orderItem.quantity,
      };
    } else {
      _values.push({
        itemId: orderItem.itemId,
        quantity: orderItem.quantity,
      });
    }

    for (let jindex = 0; jindex < orderItem.optionItems.length; jindex++) {
      const optionItem = orderItem.optionItems[jindex];
      const _i = _values.findIndex((_v) => _v.itemId === optionItem.itemId);
      if (_i >= 0) {
        _values[_i] = {
          itemId: _values[_i].itemId,
          quantity: _values[_i].quantity + orderItem.quantity,
        };
      } else {
        _values.push({
          itemId: optionItem.itemId,
          quantity: orderItem.quantity,
        });
      }
    }
  }
  return _values;
};

export const finishedCourseItemIds = (orders: Order[]): string[] => {
  return orders
    .filter((_o) => {
      if (_o.orderItem.timeLimitedMin === null) {
        return false;
      }
      if (_o.orderItem.courseItem) {
        const _now = new Date();
        const _finishTime = new Date(
          _o.createdAt.setMinutes(
            _o.createdAt.getMinutes() + _o.orderItem.timeLimitedMin
          )
        );
        if (_now > _finishTime) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    })
    .map((_o) => _o.orderItem.itemId);
};

export const ordersToOkawariItems = (
  orders: Order[],
  items: Item[]
): (Item & { defaultOptionItems: OptionItem[] })[] => {
  return orders
    .filter(function (x, i, array) {
      // 全く同じ注文内容の重複削除
      return (
        array.findIndex(function (y) {
          return (
            y.orderItem.itemId === x.orderItem.itemId &&
            x.orderItem.optionItems.every((_oItem) =>
              y.orderItem.optionItems.some(
                (_xOItem) =>
                  _oItem.itemId === _xOItem.itemId &&
                  _oItem.groupName.ja === _xOItem.groupName.ja
              )
            ) &&
            x.orderItem.optionItems.length === y.orderItem.optionItems.length
          );
        }) === i
      );
    })
    .map((order, i) => {
      const _items: Item[] = items.filter(
        (_item) => _item.id === order.orderItem.itemId
      );
      if (_items.length === 0) {
        return undefined;
      }
      return {
        ..._items[0],
        defaultOptionItems: order.orderItem.optionItems,
      };
    })
    .filter(
      (_v): _v is Item & { defaultOptionItems: OptionItem[] } =>
        _v !== undefined
    );
};
