import { Text, Stack, Flex } from '@chakra-ui/react';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyle } from './hooks/useStyle';

export const NotFound: VFC = () => {
  const { t } = useTranslation();
  const { fontFamily } = useStyle();

  return (
    <Stack w={window.innerWidth} h={window.innerHeight} bg="gray.300">
      <Flex
        justify="center"
        flexDirection="column"
        height="100vh"
        justifyContent="space-evenly"
      >
        <Text
          textAlign="center"
          color="grey"
          fontSize="xx-small"
          fontFamily={fontFamily}
        >
          {t('readQR')}
        </Text>
      </Flex>
    </Stack>
  );
};
