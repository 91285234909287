import { ComponentStyleConfig } from "@chakra-ui/react";

export const Form: ComponentStyleConfig = {
  baseStyle: {
    helperText: {
      color: "gray.900",
      fontSize: "xs",
      lineHeight: "tall",
      marginTop: "2",
    },

    requiredIndicator: {
      _before: {
        backgroundColor: "red.500",
        borderRadius: "sm",
        color: "white",
        fontSize: "xs",
        fontWeight: "bold",
        height: "4",
        paddingX: "1",
        width: "auto",
        content: '"必須"',
      },
      fontSize: "0",
      marginLeft: "2",
      height: "4",
    },
  },
};
