import { ComponentStyleConfig } from "@chakra-ui/react";

export const Textarea: ComponentStyleConfig = {
  baseStyle: {
    _disabled: { backgroundColor: "gray.50" },
    _invalid: { border: "2px", borderColor: "red.500" },
    backgroundColor: "white",
    border: "3px",
    borderColor: "gray.100",
    color: "gray.900",
    fontSize: "md",
    lineHeight: "tall",
    resize: "vertical",
  },
};
