import {
  Badge,
  Box,
  Flex,
  Button,
  List,
  ListItem,
  Stack,
  Text,
  Image,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import useSWR from 'swr';
import { useHistory } from 'react-router-dom';
import { useCustomer } from '../../../hooks/useCustomer';
import { getOrders } from '../../../repositories/order';
import { PaymentButton } from '../pay/paymentButton';
import { useCart } from '../../../hooks/useCart';
import { VFC } from 'react';
import { orderItemTotal } from '../../../utils/calculator';
import { useStyle } from '../../../hooks/useStyle';
import { useTranslation } from 'react-i18next';
import { useAppTranslate } from '../../../hooks/useTranslation';
import { isAndroid } from '../../../utils/device';

export const TransactionPage: VFC = () => {
  const { merchantId, store } = useCustomer();
  const { trans, customers } = useCart();
  const history = useHistory();
  const { cBg, fontFamily } = useStyle();
  const { t } = useTranslation();
  const { translate } = useAppTranslate();

  const { data: orders } = useSWR(
    ['/orders', merchantId, store?.id, trans?.id],
    (_, mId, sId) => getOrders(mId, sId, trans?.id ?? '')
  );

  return (
    <>
      <Stack
        w={window.innerWidth}
        h={window.innerHeight}
        overflow="auto"
        bg={cBg}
      >
        {!orders || orders?.length === 0 ? (
          <Flex
            justify="center"
            flexDirection="column"
            height="100vh"
            width="100%"
          >
            <Text
              fontWeight="normal"
              fontSize="md"
              textAlign="center"
              color="gray.500"
              fontFamily={fontFamily}
            >
              {t('noOrderHistories')}
            </Text>
          </Flex>
        ) : (
          <List>
            <Stack h="5" />
            {orders
              ?.filter((_o) => _o.orderItem.quantity > 0)
              .map((order, i) => {
                const item = order.orderItem;
                return (
                  <ListItem
                    key={`${item.itemId}${i}`}
                    p="4"
                    borderBottom="1px"
                    borderColor="blackAlpha.300"
                  >
                    <Flex alignItems="center" justifyContent="space-between">
                      <Box w="10%" alignItems="center">
                        {customers.some(
                          (_cus) => _cus.id === order.customerId
                        ) &&
                        customers.find((_cus) => _cus.id === order.customerId)!
                          .pictureUrl ? (
                          <Box
                            boxSize={`${window.innerWidth * 0.075}px`}
                            borderRadius="full"
                          >
                            <Image
                              src={
                                customers.find(
                                  (_cus) => _cus.id === order.customerId
                                )!.pictureUrl!
                              }
                              objectFit="cover"
                              borderRadius="full"
                              alignSelf="center"
                            />
                          </Box>
                        ) : (
                          <Box
                            w={window.innerWidth * 0.08}
                            h={
                              isAndroid
                                ? `${window.innerWidth * 0.08}px`
                                : window.innerWidth * 0.08
                            }
                          />
                        )}
                      </Box>
                      <Box w="70%">
                        <Flex flexDirection="row">
                          <Badge
                            colorScheme="gray"
                            fontSize="xs"
                            fontWeight="bold"
                            fontFamily={fontFamily}
                          >
                            {dayjs(order.createdAt).format('HH:mm')}
                            {order.createdStaff ? ` ${t('orderByStaff')}` : ''}
                          </Badge>
                          <Box w={window.innerWidth * 0.02} />
                          <Badge
                            colorScheme={
                              item.status === 'delivered' ? 'gray' : 'white'
                            }
                            fontSize="xs"
                            fontWeight="semibold"
                            textColor="black"
                            fontFamily={fontFamily}
                          >
                            {item.status === 'delivered'
                              ? t('isReady')
                              : t('preparing')}
                          </Badge>
                        </Flex>
                        <Text
                          fontSize="md"
                          fontWeight="bold"
                          fontFamily={fontFamily}
                        >
                          {translate(item.itemName)}
                        </Text>
                      </Box>
                      <Flex w="20%" direction="column" alignItems="flex-end">
                        <Text
                          fontSize="sm"
                          fontWeight="light"
                          fontFamily={fontFamily}
                        >
                          {item.quantity} {t('per1')}
                        </Text>
                        <Text
                          fontSize="xs"
                          fontWeight="light"
                          fontFamily={fontFamily}
                        >
                          ¥{orderItemTotal(item)}({t('includeTax')})
                        </Text>
                      </Flex>
                    </Flex>
                    <Stack
                      direction="column"
                      paddingTop="2"
                      paddingLeft={window.innerWidth * 0.08}
                    >
                      {item.optionItems?.map((oItem, i) => (
                        <Text
                          fontSize="xs"
                          fontWeight="light"
                          key={`${oItem.itemId}${i}`}
                          fontFamily={fontFamily}
                        >
                          {`${translate(oItem.groupName)}：${translate(
                            oItem.itemName
                          )}`}
                        </Text>
                      ))}
                    </Stack>
                  </ListItem>
                );
              })}
            <Stack h={window.innerHeight * 0.2} />
          </List>
        )}
        <Stack
          pos="fixed"
          bottom="10"
          right={window.innerWidth * 0.01}
          left={window.innerWidth * 0.01}
        >
          <Flex justifyContent="center">
            <Button
              alignSelf="center"
              p="6"
              onClick={() => history.goBack()}
              w={window.innerWidth * 0.3}
              h={window.innerHeight * 0.11}
              borderWidth="2px"
              bg="#F2F2F2"
              shadow="lg"
              borderRadius="lg"
            >
              <Text
                fontSize="md"
                fontWeight="bold"
                textShadow="text"
                textColor="white"
                fontFamily={fontFamily}
              >
                {t('back')}
              </Text>
            </Button>
            <Box w={window.innerWidth * 0.007} />
            <PaymentButton orders={orders} />
          </Flex>
        </Stack>
      </Stack>
    </>
  );
};
