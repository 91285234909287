import {
  Box,
  Flex,
  Spinner,
  Stack,
  Text,
  Image,
  Grid,
  GridItem,
  Divider,
  Circle,
  Button,
} from '@chakra-ui/react';
import { MutableRefObject, useRef, useState, VFC } from 'react';
import { useCustomer } from '../../../hooks/useCustomer';
import { TransHistory, useHistory } from '../../../hooks/useHistory/useHistory';
import { Item, OptionItem, Store } from 'smartdishpro-scripts/ts_common';
import { ItemDialog } from './itemDialog';
import { useAppTranslate } from '../../../hooks/useTranslation';
import { isAndroid } from '../../../utils/device';
import { useTranslation } from 'react-i18next';
import { useStyle } from '../../../hooks/useStyle';
import { dateToMD, dateToStringYMD } from '../../../utils/date';
import { ordersToOkawariItems } from '../../../utils/converter';
import useSWR from 'swr';
import { useCart } from '../../../hooks/useCart';
import { getOrders } from '../../../repositories/order';
import { getTransactionOrders } from '../../../repositories/transaction';

type Props = {
  items: Item[];
  initialDialog: boolean;
  onClose: () => void;
};

export const TransactionHistory: VFC<Props> = ({
  items,
  initialDialog,
  onClose,
}) => {
  const { merchantId, store, stampExpiredAt, transactionHistories } =
    useCustomer();
  const { trans } = useCart();

  const { data: histories } = useSWR(
    ['/transHistories/orders', merchantId, store?.id, trans?.id],
    (_, mId, sId) => getTransactionOrders(mId, sId, transactionHistories)
  );

  const { data: orders } = useSWR(
    ['/orders', merchantId, store?.id, trans?.id],
    (_, mId, sId) => getOrders(mId, sId, trans?.id ?? '')
  );

  const _items = useHistory(histories, items);
  const _okawariItems: (Item & { defaultOptionItems: OptionItem[] })[] =
    ordersToOkawariItems(orders ?? [], items);
  const [isOpen, setIsOpen] = useState(false);
  const [showEventsDes, setShowEventsDes] = useState(false);
  const [item, setItem] = useState<
    (Item & { defaultOptionItems: OptionItem[] }) | undefined
  >();
  const cancelRef = useRef() as MutableRefObject<HTMLInputElement>;
  const { translate } = useAppTranslate();
  const { t } = useTranslation();
  const { fontFamily } = useStyle();

  return initialDialog ? (
    <Stack
      overflowY="auto"
      h={isAndroid ? `${window.innerHeight * 0.4}px` : window.innerHeight * 0.4}
      flexDirection="column"
    >
      <Flex direction="row" alignItems="end">
        <Box flex="1" />
        <Box flex="1">
          <Text
            w="100%"
            paddingTop="2"
            textAlign="center"
            fontSize="md"
            fontWeight="normal"
            textColor="black"
            fontFamily={fontFamily}
          >
            {`${t('customerInfo')}${t('customerInfo2')}`}
          </Text>
        </Box>
        <Box flex="1">
          <Text
            w="100%"
            paddingTop="2"
            textAlign="end"
            fontSize="xx-small"
            fontWeight="normal"
            textColor="black"
            fontFamily={fontFamily}
          >
            (過去10回分)
          </Text>
        </Box>
      </Flex>
      <Divider />
      <Stamps
        transactions={transactionHistories.slice(
          transactionHistories.length <= 10
            ? 0
            : transactionHistories.length - 10
        )}
        store={store}
      />
      <Box h={window.innerHeight * 0.02} />
      <Button
        w={window.innerWidth * 0.6}
        height={window.innerHeight * 0.07}
        alignSelf="center"
        onClick={onClose}
        borderWidth="2px"
        bg="#FFFFFF"
      >
        <Text
          paddingY="2"
          fontSize="md"
          fontWeight="bold"
          textColor="black"
          fontFamily={fontFamily}
        >
          {t('toOrderPage')}
        </Text>
      </Button>
      )
    </Stack>
  ) : (
    <>
      <Stack
        h={
          isAndroid ? `${window.innerHeight * 0.8}px` : window.innerHeight * 0.8
        }
        overflowY="auto"
      >
        {showEventsDes ? (
          <>
            <Button
              alignSelf="flex-start"
              onClick={() => setShowEventsDes(!showEventsDes)}
              borderWidth="2px"
              bg="#F2F2F2"
              paddingBottom="3px"
            >
              <Text
                paddingY="2"
                fontSize="small"
                fontWeight="bold"
                textColor="black"
                fontFamily={fontFamily}
              >
                {t('back')}
              </Text>
            </Button>
            {store?.stampEventsDetailImageUrl ? (
              <Image
                w={window.innerHeight * 0.7}
                src={store!.stampEventsDetailImageUrl!}
                objectFit="cover"
                borderTopRadius="lg"
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            {_okawariItems.length === 0 ? (
              <></>
            ) : (
              <>
                <Text
                  w="100%"
                  paddingY="2"
                  textAlign="center"
                  fontSize="md"
                  fontWeight="normal"
                  textColor="black"
                  fontFamily={fontFamily}
                >
                  {t('doOkawari')}
                </Text>
                <Divider />
                <Flex justifyContent="start" flexDirection="column">
                  {_okawariItems?.map((item, _i) => {
                    return (
                      <Box
                        key={`${_i}${item.id}`}
                        onClick={() => {
                          if (!item.soldOut) {
                            setItem(item);
                            setIsOpen(true);
                          }
                        }}
                        paddingX={1}
                        paddingY={1}
                      >
                        <Box
                          shadow={item.soldOut ? undefined : 'sm'}
                          borderWidth={window.innerHeight * 0.002}
                          borderColor={item.soldOut ? 'gray.100' : 'gray.50'}
                          bg={item.soldOut ? 'gray.100' : 'white'}
                          borderRadius="lg"
                        >
                          <Flex
                            w="100%"
                            h={window.innerHeight * 0.06}
                            alignItems="center"
                            direction="row"
                            paddingX={1}
                            paddingY={2}
                            justifyContent="start"
                          >
                            <Flex
                              w="100%"
                              justifyContent="space-between"
                              alignItems="center"
                              direction="row"
                            >
                              <Text
                                w={
                                  isAndroid
                                    ? `${
                                        window.innerWidth * (_i < 3 ? 0.6 : 0.8)
                                      }px`
                                    : window.innerWidth * (_i < 3 ? 0.6 : 0.8)
                                }
                                h="100%"
                                fontWeight="semibold"
                                fontSize="medium"
                                isTruncated
                                as={item.soldOut ? 'del' : undefined}
                                paddingLeft="3%"
                                fontFamily={fontFamily}
                              >
                                {translate(item.itemName)}
                              </Text>
                            </Flex>
                          </Flex>
                        </Box>
                      </Box>
                    );
                  })}
                </Flex>
                <Box
                  minH={
                    isAndroid
                      ? `${window.innerHeight * 0.05}px`
                      : window.innerHeight * 0.05
                  }
                />
              </>
            )}
            {store?.stampFunctionInUse ?? false ? (
              <>
                <Text
                  w="100%"
                  paddingTop="2"
                  textAlign="center"
                  fontSize="md"
                  fontWeight="normal"
                  textColor="black"
                  fontFamily={fontFamily}
                >
                  {`${t('customerInfo')}${t('customerInfo2')}`}
                </Text>
                <Divider />
                <Stamps transactions={transactionHistories} store={store} />
                <Box
                  h={
                    isAndroid
                      ? `${window.innerHeight * 0.1}px`
                      : window.innerHeight * 0.1
                  }
                />
                {stampExpiredAt !== null ? (
                  <Text
                    alignSelf="center"
                    paddingY="1"
                    textAlign="center"
                    fontSize="small"
                    fontWeight="normal"
                    textColor="black"
                    fontFamily={fontFamily}
                  >
                    {`有効期限${dateToStringYMD(stampExpiredAt)}まで`}
                  </Text>
                ) : (
                  <></>
                )}
                {store!.stampEventsDetailImageUrl !== null ? (
                  <Button
                    alignSelf="center"
                    onClick={() => setShowEventsDes(!showEventsDes)}
                    borderWidth="2px"
                    bg="#F2F2F2"
                  >
                    <Text
                      paddingY="2"
                      fontSize="small"
                      fontWeight="bold"
                      textColor="black"
                      fontFamily={fontFamily}
                    >
                      詳細
                    </Text>
                  </Button>
                ) : (
                  <></>
                )}
                <Box
                  minH={
                    isAndroid
                      ? `${window.innerHeight * 0.05}px`
                      : window.innerHeight * 0.05
                  }
                />
              </>
            ) : (
              <></>
            )}
            <Text
              w="100%"
              paddingY="2"
              paddingTop="5"
              textAlign="center"
              fontSize="md"
              fontWeight="normal"
              textColor="black"
              fontFamily={fontFamily}
            >
              {t('itemsYouOftenOrder')}
            </Text>
            <Divider />
            <Flex justifyContent="start" flexDirection="column">
              {!_items ? (
                <Spinner alignSelf="center" />
              ) : _items.length === 0 ? (
                <Text
                  fontWeight="normal"
                  fontSize="md"
                  textAlign="center"
                  color="gray.500"
                  fontFamily={fontFamily}
                >
                  {t('noOrderHistories')}
                </Text>
              ) : (
                _items?.map((_item, _i) => {
                  const item = _item.item;
                  return (
                    <Box
                      key={`${_i}${item.id}`}
                      onClick={() => {
                        if (!item.soldOut) {
                          setItem({ ...item, defaultOptionItems: [] });
                          setIsOpen(true);
                        }
                      }}
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box
                        shadow={item.soldOut ? undefined : 'sm'}
                        borderWidth={window.innerHeight * 0.002}
                        borderColor={item.soldOut ? 'gray.100' : 'gray.50'}
                        bg={item.soldOut ? 'gray.100' : 'white'}
                        borderRadius="lg"
                      >
                        <Flex
                          w="100%"
                          h={window.innerHeight * 0.06}
                          alignItems="center"
                          direction="row"
                          paddingX={1}
                          paddingY={2}
                          justifyContent="start"
                        >
                          {_i < 3 ? (
                            <Image
                              w="13%"
                              h={window.innerHeight * 0.045}
                              src={
                                _i === 0
                                  ? '/images/1.png'
                                  : _i === 1
                                  ? '/images/2.png'
                                  : '/images/3.png'
                              }
                              objectFit="fill"
                            />
                          ) : (
                            <></>
                          )}
                          <Flex
                            w="100%"
                            justifyContent="space-between"
                            alignItems="center"
                            direction="row"
                          >
                            <Text
                              w={
                                isAndroid
                                  ? `${
                                      window.innerWidth * (_i < 3 ? 0.6 : 0.8)
                                    }px`
                                  : window.innerWidth * (_i < 3 ? 0.6 : 0.8)
                              }
                              h="100%"
                              fontWeight="semibold"
                              fontSize="medium"
                              isTruncated
                              as={item.soldOut ? 'del' : undefined}
                              paddingLeft="3%"
                              fontFamily={fontFamily}
                            >
                              {translate(item.itemName)}
                            </Text>
                          </Flex>
                        </Flex>
                      </Box>
                    </Box>
                  );
                })
              )}
            </Flex>
          </>
        )}
      </Stack>
      {item ? (
        <ItemDialog
          item={{ ...item, okawari: true }}
          isOpen={isOpen}
          cancelRef={cancelRef}
          onClose={() => {
            setIsOpen(false);
            setItem(undefined);
          }}
          afterAddedCart={() => {
            setIsOpen(false);
            onClose();
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

type StampsProps = {
  transactions: TransHistory[] | undefined;
  store: Store | undefined;
};

const Stamps: VFC<StampsProps> = ({ transactions, store }) => {
  const { fontFamily, cStampActiveBg, cStampBg } = useStyle();
  return (
    <Grid
      templateColumns="repeat(5, 1fr)"
      gap={5}
      alignItems="center"
      paddingX={1}
    >
      {transactions?.map((_date, index) => (
        <GridItem
          key={_date.trans.id}
          w={
            isAndroid ? `${window.innerWidth * 0.1}px` : window.innerWidth * 0.1
          }
          h={
            isAndroid ? `${window.innerWidth * 0.1}px` : window.innerWidth * 0.1
          }
        >
          <Flex
            h={
              isAndroid
                ? `${window.innerWidth * 0.1}px`
                : window.innerWidth * 0.1
            }
            direction="column"
            justifyContent="start"
          >
            <Circle
              size={
                isAndroid
                  ? `${window.innerWidth * 0.1}px`
                  : window.innerWidth * 0.1
              }
              color="white"
              bg={
                store?.stampEventsVisitedDays.includes(index + 1)
                  ? cStampActiveBg
                  : cStampBg
              }
            >
              <Circle
                size={
                  isAndroid
                    ? `${window.innerWidth * 0.09}px`
                    : window.innerWidth * 0.09
                }
                color="white"
                bg="white"
              >
                <Circle
                  size={
                    isAndroid
                      ? `${window.innerWidth * 0.08}px`
                      : window.innerWidth * 0.08
                  }
                  bg={
                    store?.stampEventsVisitedDays.includes(index + 1)
                      ? cStampActiveBg
                      : cStampBg
                  }
                >
                  <Text
                    w="100%"
                    textAlign="center"
                    fontSize="x-small"
                    fontWeight="normal"
                    textColor="white"
                    fontFamily={fontFamily}
                  >
                    {dateToMD(_date.trans.createdAt)}
                  </Text>
                </Circle>
              </Circle>
            </Circle>
            {index + 1 === transactions.length ? (
              <Text
                alignSelf="center"
                textAlign="center"
                fontSize="xx-small"
                fontWeight="bold"
                textColor="red"
                fontFamily={fontFamily}
              >
                NEW
              </Text>
            ) : (
              <></>
            )}
          </Flex>
        </GridItem>
      )) ?? <></>}
    </Grid>
  );
};
