import { DocumentStatus } from './common';
import { transRef } from './transaction';
import { PaymentFromJson, Collection } from 'smartdishpro-scripts/ts_common';

export const paymentsRef = (
  merchantId: string,
  storeId: string,
  transId: string
) => transRef(merchantId, storeId).doc(transId).collection(Collection.Payments);

export const getPayments = async (
  merchantId: string,
  storeId: string,
  transId: string
) => {
  const snapshot = await paymentsRef(merchantId, storeId, transId)
    .where('documentStatus', '==', DocumentStatus.active)
    .limit(100)
    .get();

  return snapshot.docs?.map((doc) =>
    PaymentFromJson(doc.data({ serverTimestamps: 'estimate' }), doc.id)
  );
};
