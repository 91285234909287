import { Button, Text } from '@chakra-ui/react';
import { useEffect, VFC } from 'react';
import { useCart, useOrder } from '../../../hooks/useCart';
import { useStyle } from '../../../hooks/useStyle';
import { useTranslation } from 'react-i18next';
import { useCustomer } from '../../../hooks/useCustomer';
import { useHistory } from 'react-router-dom';
import { route } from '../../../router';

export const SendButton: VFC = () => {
  const history = useHistory();
  const { merchantId, store } = useCustomer();
  const { isLoading, isDone, order } = useOrder();
  const { trans } = useCart();
  const { cOrderButton, fontFamily } = useStyle();
  const { t } = useTranslation();

  useEffect(() => {
    if (isDone) {
      history.push(
        `${route.root}${merchantId}/${store!.id}/${trans!.id}${route.items}`
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDone]);

  return (
    <Button
      onClick={() => order()}
      isLoading={isLoading}
      p="6"
      w={window.innerWidth * 0.6}
      h={window.innerHeight * 0.11}
      borderWidth="2px"
      shadow="lg"
      borderRadius="lg"
      bg={cOrderButton}
      colorScheme={cOrderButton}
    >
      <Text
        fontSize="md"
        fontWeight="bold"
        textShadow="text"
        textColor="white"
        fontFamily={fontFamily}
      >
        {t('confirmOrder')}
      </Text>
    </Button>
  );
};
