import { db } from '../firebase';
import {
  Order,
  OrderFromJson,
  Collection,
} from 'smartdishpro-scripts/ts_common';
import { createDocument, DocumentStatus } from './common';

const _ordersRef = (merchantId: string, storeId: string, transId: string) =>
  db
    .collection(Collection.Merchants)
    .doc(merchantId)
    .collection(Collection.Stores)
    .doc(storeId)
    .collection(Collection.Transactions)
    .doc(transId)
    .collection(Collection.Orders);

export const getOrders = async (
  merchantId: string,
  storeId: string,
  transId: string
) => {
  const snapshot = await _ordersRef(merchantId, storeId, transId)
    .where('documentStatus', '==', DocumentStatus.active)
    .orderBy('createdAt', 'desc')
    .limit(200)
    .get();
  return snapshot.docs
    ?.map((doc) =>
      OrderFromJson(doc.data({ serverTimestamps: 'estimate' }), doc.id)
    )
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
    .sort((a, b) =>
      b.createdAt.getTime() - a.createdAt.getTime() === 0
        ? a.sortNum - b.sortNum
        : 0
    );
};

export const createOrders = async (
  merchantId: string,
  storeId: string,
  transId: string,
  orders: Order[]
) => {
  const _batch = db.batch();
  for (const order of orders) {
    _batch.set(
      _ordersRef(merchantId, storeId, transId).doc(order.id),
      createDocument(order)
    );
  }
  await _batch.commit();
};
