import { useCallback, VFC } from 'react';
import {
  Flex,
  Icon,
  List,
  ListItem,
  Text,
  Box,
  HStack,
  Button,
  Stack,
} from '@chakra-ui/react';
import { useCart, CartActionType } from '../../../hooks/useCart';
import { OrderItem } from 'smartdishpro-scripts/ts_common';
import { useHistory } from 'react-router-dom';
import { orderItemTotal } from '../../../utils/calculator';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useAppTranslate } from '../../../hooks/useTranslation';
import { useStyle } from '../../../hooks/useStyle';

export const CartItems: VFC = () => {
  const { items, dispatch: cartDispatch } = useCart();
  const history = useHistory();
  const { t } = useTranslation();
  const { translate } = useAppTranslate();
  const { fontFamily } = useStyle();

  const handleQuantity = useCallback(
    (
      item: OrderItem,
      type:
        | CartActionType.PLUS_ITEM
        | CartActionType.MINUS_ITEM
        | CartActionType.REMOVE_ITEM
    ) => {
      cartDispatch({
        type: type,
        payload: item,
      });
    },
    [cartDispatch]
  );

  return (
    <List paddingX="8%">
      <Box h="5" />
      {items
        .filter((item) => item.quantity !== 0)
        .map((item, i) => (
          <ListItem
            key={`${item.itemId}${i}`}
            paddingY="5"
            borderBottom="1px"
            borderColor="blackAlpha.300"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Text
                fontSize="md"
                fontWeight="bold"
                w={window.innerWidth * 0.5}
                fontFamily={fontFamily}
              >
                {translate(item.itemName)}
              </Text>
              <Text fontSize="sm" fontFamily={fontFamily}>
                ¥{orderItemTotal(item)}({t('includeTax')})
              </Text>
            </Flex>
            <Stack flexDirection="column">
              {item.optionItems?.map((_optionItem, i) => {
                return _optionItem ? (
                  <Flex
                    key={`${i}${_optionItem.itemId}`}
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Text
                      fontSize="xs"
                      fontWeight="light"
                      fontFamily={fontFamily}
                    >{`${translate(_optionItem.groupName)}：${translate(
                      _optionItem.itemName
                    )}`}</Text>
                  </Flex>
                ) : (
                  <Box key={i}></Box>
                );
              })}
            </Stack>
            <Box h="2" />
            <Flex justifyContent="space-between" alignItems="flex-end">
              <Box />
              <HStack>
                <Button
                  size="md"
                  _focus={{
                    ringColor: 'white',
                  }}
                >
                  <Icon
                    w={6}
                    h={6}
                    as={AiOutlineMinus}
                    onClick={() => {
                      if (item.quantity > 1) {
                        handleQuantity(item, CartActionType.MINUS_ITEM);
                      } else {
                        handleQuantity(item, CartActionType.REMOVE_ITEM);
                        if (items.length === 1) history.goBack();
                      }
                    }}
                  />
                </Button>
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  _focus={{
                    ringColor: 'white',
                  }}
                  fontFamily={fontFamily}
                >
                  {item.quantity}
                </Text>
                <Button
                  size="md"
                  _focus={{
                    ringColor: 'white',
                  }}
                >
                  <Icon
                    w={6}
                    h={6}
                    as={AiOutlinePlus}
                    onClick={() =>
                      handleQuantity(item, CartActionType.PLUS_ITEM)
                    }
                  />
                </Button>
              </HStack>
            </Flex>
          </ListItem>
        ))}
      <Box h={window.innerHeight * 0.2} />
    </List>
  );
};
