import { OrderItem } from 'smartdishpro-scripts/ts_common';

// (オプションの合計 + 商品の価格(税込)) × 個数
export const orderItemTotal = (item: OrderItem): number => {
  let total = 0;
  item.optionItems.forEach((oItem) => {
    total += oItem.priceWTaxPerItem;
  });
  return Math.floor((item.priceWTaxPerItem + total) * item.quantity);
};

// 小計(消費税適用、クーポン適用なし)で使用
export const syoukei = (oItems: OrderItem[]): number => {
  let total = 0;
  oItems.forEach((item) => {
    total += orderItemTotal(item);
  });
  return total;
};
