import {
  Flex,
  Text,
  Button,
  Input,
  FormLabel,
  FormErrorMessage,
  RadioGroup,
  Stack,
  Radio,
  CheckboxGroup,
  Checkbox,
  useToast,
  Textarea,
} from '@chakra-ui/react';
import { VFC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { useCustomer } from '../../../hooks/useCustomer';
import { useStyle } from '../../../hooks/useStyle';
import { getCustomerDoc } from '../../../repositories/customers';
import { getSurvey } from '../../../repositories/survey';
import { createSurveyResult } from '../../../repositories/surveyResult';
import { route } from '../../../router';

export const SurveyPage: VFC = () => {
  const history = useHistory();

  const toast = useToast();

  const { merchantId, store, id } = useCustomer();

  const _surveyId = useLocation().pathname.split('/')[4];

  const { fontFamily } = useStyle();

  const { data: survey } = useSWR([`/survey`, merchantId, _surveyId], () =>
    getSurvey(merchantId!, _surveyId)
  );

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  function onSubmit(value: { [key: string]: string | string[] | undefined }) {
    return new Promise(async (resolve) => {
      setTimeout(async () => {
        const _mCustomerId = (
          await getCustomerDoc(merchantId!, store!.id!, id!)
        )?.mCustomerId;

        if (!!!_mCustomerId || !!!merchantId) {
          toast({
            title: `エラーが発生しました。再度ページを開き直してください。`,
            status: 'error',
            position: 'top',
            isClosable: true,
          });
          return;
        }

        const existNotAnswer = survey?.questions.filter(
          (_question) => _question.required && !!!value[_question.id]
        );
        if ((existNotAnswer?.length ?? 0) > 0) {
          toast({
            title: existNotAnswer![0].question + 'は必須項目です',
            status: 'error',
            position: 'top',
            isClosable: true,
          });
          return;
        }

        try {
          await createSurveyResult(merchantId, {
            answeredStoreId: store?.id ?? '',
            answers:
              survey?.questions.map((_question) => {
                const _findAnswer = value[_question.id];
                return {
                  answerArray:
                    typeof _findAnswer === 'string'
                      ? null
                      : _findAnswer ?? null,
                  answerStr:
                    typeof _findAnswer === 'string'
                      ? _findAnswer === ''
                        ? null
                        : _findAnswer
                      : null,
                  id: _question.id,
                  question: _question.question,
                  type: _question.type,
                };
              }) ?? [],
            createdAt: new Date(),
            deletedAt: null,
            documentStatus: 'active',
            mCustomerId: _mCustomerId,
            surveyId: _surveyId,
            surveyTitle: survey?.title ?? '',
            updatedAt: new Date(),
          });

          toast({
            title: `登録が完了しました`,
            status: 'success',
            position: 'top',
            isClosable: true,
          });

          history.push(
            `${route.root}${merchantId}/${store?.id}${route.surveyComplete}`
          );
        } catch (error) {
          console.log(error);
        }
        resolve(null);
      }, 3000);
    });
  }

  return !!!survey ? (
    <Flex
      height={window.innerHeight}
      alignItems="center"
      justifyContent="center"
    >
      <Text textAlign="center" fontFamily={fontFamily}>
        アンケート情報の取得に失敗
      </Text>
    </Flex>
  ) : !survey.availableStoreIds.includes(store?.id ?? '') ? (
    <Flex
      height={window.innerHeight}
      alignItems="center"
      justifyContent="center"
    >
      <Text textAlign="center" fontFamily={fontFamily}>
        このアンケートはご利用いただけません
      </Text>
    </Flex>
  ) : (
    <Flex direction="column" p={30}>
      <Text textAlign="center" p={30}>
        {survey.title}
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        {survey.questions.map((_question) => (
          <div key={_question.id} style={{ paddingBottom: '20px' }}>
            <FormLabel htmlFor="name" style={{ whiteSpace: 'pre-line' }}>
              {_question.question}
              {_question.required ? (
                <Text fontSize={10} color="red.200">
                  ※
                </Text>
              ) : (
                ''
              )}
            </FormLabel>
            <Controller
              name={_question.id}
              control={control}
              rules={{ required: _question.required }}
              render={({ field: { ref, ...field } }) => {
                return _question.type === 'radio' ? (
                  <RadioGroup {...field}>
                    <Stack direction={['column', 'row']}>
                      {_question.selections?.map((_selection, index) => {
                        return (
                          <Radio key={_selection + index} value={_selection}>
                            {_selection}
                          </Radio>
                        );
                      })}
                    </Stack>
                  </RadioGroup>
                ) : _question.type === 'checkbox' ? (
                  <CheckboxGroup {...field}>
                    <Stack spacing={[1, 5]} direction={['column', 'row']}>
                      {_question.selections?.map((_selection, index) => {
                        return (
                          <Checkbox key={_selection + index} value={_selection}>
                            {_selection}
                          </Checkbox>
                        );
                      })}
                    </Stack>
                  </CheckboxGroup>
                ) : _question.type === 'number' ? (
                  <Input
                    id={_question.id}
                    type="number"
                    defaultValue={undefined}
                    {...register(
                      _question.id,
                      _question.required
                        ? {
                            required: 'この項目は必須です',
                          }
                        : {}
                    )}
                  />
                ) : (
                  <Textarea
                    id={_question.id}
                    defaultValue={undefined}
                    {...register(
                      _question.id,
                      _question.required
                        ? {
                            required: 'この項目は必須です',
                          }
                        : {}
                    )}
                  />
                );
              }}
            />
          </div>
        ))}
        <FormErrorMessage>{errors}</FormErrorMessage>
        <div style={{ height: '10px' }}></div>
        <Button
          mt={4}
          colorScheme="teal"
          isLoading={isSubmitting}
          type="submit"
        >
          確定
        </Button>
      </form>
    </Flex>
  );
};
